import { type ReactElement, type SyntheticEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';

import { LinkIcon, PreviewButton, SelectButton } from '@xeris/components';
import { type BasicOption } from '@xeris/pages/product/types';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';

import { selectOptions, variantGeneratorSelectors } from '../../../reducer';

type OptionButtonsProps = {
    featureId: string;
    option: BasicOption;
    handleSelectPreview: () => void;
    isPreviewable: boolean;
    isSelectable: boolean;
};

export const OptionCardButtons = ({
    featureId,
    option,
    handleSelectPreview,
    isPreviewable,
    isSelectable,
}: OptionButtonsProps): ReactElement => {
    const { t } = useTranslation('product');
    const dispatch = useAppDispatch();

    const { isPreviewed, isSelected } = useAppSelector((state) =>
        variantGeneratorSelectors.optionStatus(state, featureId, option.id)
    );

    const handleToggleOption = useCallback(
        (event: SyntheticEvent): void => {
            event.stopPropagation();
            if (!isSelected) {
                handleSelectPreview();
            }

            dispatch(
                selectOptions({
                    optionIds: [option.id],
                    featureId: featureId,
                    newValue: !isSelected,
                })
            );
        },
        [dispatch, featureId, handleSelectPreview, isSelected, option.id]
    );

    const _handleSelectPreview = (event: SyntheticEvent): void => {
        event.stopPropagation();
        event.preventDefault();
        handleSelectPreview();
    };

    const disablePreview = !isPreviewable;
    const disableSelect = !isSelectable && !isSelected; // If the option is selected, it should be possible to deselect it

    return (
        <Stack spacing={1}>
            <PreviewButton
                className={'preview'}
                sx={{ opacity: isPreviewed ? 1 : 0 }}
                onSelectPreview={_handleSelectPreview}
                isPreviewActive={isPreviewed}
                isDisabled={disablePreview}
                badgeContent={
                    disablePreview ? (
                        <LinkIcon color={'icons'} sx={{ minWidth: '20px' }} />
                    ) : undefined
                }
            />

            <SelectButton
                badgeContent={
                    disableSelect ? (
                        <LinkIcon color={'icons'} sx={{ minWidth: '20px' }} />
                    ) : undefined
                }
                disabledTooltip={t('variantGenerator.optionNotAvailableSelect')}
                isSelected={isSelected}
                onToggleOption={handleToggleOption}
                disabled={disableSelect}
            />
        </Stack>
    );
};
