import { type FormData } from '@xeris/pages/admin/Connections/forms/common/connectionSchema';
import {
    type DataEntry,
    type FormInputData,
} from '@xeris/pages/admin/Connections/forms/common/useGetFormData';

export const convertRulesToFormData = (
    data: FormInputData
): Pick<FormData, 'accessRuleType' | 'accessToBrands' | 'accessToMarkets'> => {
    if (data.rules.length === 0)
        return {
            accessRuleType: 'none',
            accessToBrands: [],
            accessToMarkets: [],
        };

    if (data.rules.some((rule) => rule.__typename === 'BrandAccessRule'))
        return {
            accessRuleType: 'brands',
            accessToBrands: data.rules
                .map((rule) =>
                    rule.__typename === 'BrandAccessRule'
                        ? rule.targetBrand
                        : null
                )
                .map((brandId) =>
                    data.brandList.find((brand) => brand.id === brandId)
                )
                .filter((brand): brand is DataEntry => !!brand)
                .map((brand) => ({ ...brand, label: brand.name })),
            accessToMarkets: [],
        };
    if (data.rules.some((rule) => rule.__typename === 'MarketAccessRule'))
        return {
            accessRuleType: 'markets',
            accessToBrands: [],
            accessToMarkets: data.rules
                .map((rule) =>
                    rule.__typename === 'MarketAccessRule'
                        ? rule.targetMarket
                        : null
                )
                .map((marketId) =>
                    data.marketList.find((market) => market.id === marketId)
                )
                .filter((market): market is DataEntry => !!market)
                .map((market) => ({ ...market, label: market.name })),
        };

    if (data.rules.some((rule) => rule.__typename === 'OrganizationAccessRule'))
        return {
            accessRuleType: 'organization',
            accessToBrands: [],
            accessToMarkets: [],
        };

    return {
        accessRuleType: 'none',
        accessToBrands: [],
        accessToMarkets: [],
    };
};
