import { type ReactElement, type ReactNode } from 'react';
import { Transition } from 'react-transition-group';

import { Portal } from '@mui/material';

import { useElementRect } from '@xeris/hooks';

import {
    type AlignType,
    type AnchorType,
    getTransitionStyles,
} from './SlidingDrawer';

import './index.scss';

type InlineDrawerProps = {
    zIndex?: number;
    children: ReactNode;
    align?: AlignType;
    show?: boolean;
    disableScroll?: boolean;
    /**
     * by default the drawer aligns on top where is located, bottom to the end of the window
     * and left and right to the end of the screen depending if align is left or right.
     * The target property can be used to override any of these alignment anchors
     */
    anchor?: AnchorType;
    duration: number;
};

const InlineDrawer = ({
    zIndex = 2,
    align = 'right',
    children,
    anchor = {},
    disableScroll = false,
    show = false,
    duration,
}: InlineDrawerProps): ReactElement => {
    const [rect, ref] = useElementRect();

    const transitionStyles = getTransitionStyles(align, rect, anchor);

    return (
        <>
            <div ref={ref} className="rect-div" />

            <Portal>
                <Transition in={show} timeout={duration}>
                    {(state): ReactElement => (
                        <div
                            className="sliding-drawer"
                            style={{
                                zIndex,
                                overflowY: disableScroll ? 'hidden' : 'auto',
                                transitionProperty:
                                    align === 'left' ? 'right' : 'left',
                                transition: `${duration}ms`,
                                transitionTimingFunction:
                                    state === 'entering'
                                        ? 'ease-in, opacity'
                                        : 'ease-out, opacity',
                                ...transitionStyles[state],
                            }}
                        >
                            <div
                                style={{
                                    opacity:
                                        state === 'entered' ||
                                        state === 'exiting'
                                            ? 1
                                            : 0,
                                }}
                            >
                                {children}
                            </div>
                        </div>
                    )}
                </Transition>
            </Portal>
        </>
    );
};
export default InlineDrawer;
