import {
    type ChangeEvent,
    type ReactElement,
    useCallback,
    useMemo,
    useState,
} from 'react';

import {
    Checkbox,
    debounce,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';

import { Button } from '@xeris/components';

import { type EcoSearchTypes } from '../types';

import styles from './ProductBrowser.module.scss';

type CommonFilterProps = {
    filter: EcoSearchTypes.FilterType;
    handleAddActiveFilter: (newFilter: EcoSearchTypes.FilterType) => void;
    handleRemoveActiveFilter: (filterId: string) => void;
};

type BooleanSelectorProps = CommonFilterProps & {
    filter: EcoSearchTypes.BooleanFilterType;
};

const BooleanSelector = ({
    filter,
    handleAddActiveFilter,
    handleRemoveActiveFilter,
}: BooleanSelectorProps): ReactElement => {
    //Lots of values are hardcoded due to lack of data from backend. "Will be fixed" "later"
    return (
        <div className={styles.filterGroup}>
            <FormControl component="fieldset">
                <div className={styles.filterOptions}>
                    <FormControl component="fieldset">
                        <div className={styles.formLabelWrapper}>
                            <FormLabel
                                component="legend"
                                className={styles.title}
                            >
                                {filter.name}
                            </FormLabel>
                            <Button
                                className={styles.clearButton}
                                onClick={(): void =>
                                    handleRemoveActiveFilter(filter.id)
                                }
                                variant="text"
                            >
                                X Clear
                            </Button>
                        </div>
                        <div className={styles.inputGroup}>
                            <RadioGroup
                                aria-label="EPD"
                                name="radio-buttons-group"
                                value={filter.value ?? 'no value selected'}
                            >
                                <FormControlLabel
                                    value="false"
                                    control={<Radio />}
                                    label="Not valid"
                                    onClick={(): void =>
                                        handleAddActiveFilter({
                                            ...filter,
                                            value: false,
                                        })
                                    }
                                />
                                <FormControlLabel
                                    value="true"
                                    control={<Radio />}
                                    label="Valid"
                                    onClick={(): void =>
                                        handleAddActiveFilter({
                                            ...filter,
                                            value: true,
                                        })
                                    }
                                />
                            </RadioGroup>
                        </div>
                    </FormControl>
                </div>
            </FormControl>
        </div>
    );
};

type ValueObjectType = { from: number; to: number };

type RangeSelectorProps = CommonFilterProps & {
    filter: EcoSearchTypes.RangeFilterType;
};

const RangeSelector = ({
    filter,
    handleAddActiveFilter,
    handleRemoveActiveFilter,
}: RangeSelectorProps): ReactElement => {
    const filterGroupGwp = [styles.filterGroup, styles.filtergGroupGWP];
    //Lots of values are hardcoded due to lack of data from backend. "Will be fixed" "later"
    const [value, setValue] = useState({
        to: filter.value?.to ?? 99,
        from: filter.value?.from ?? 0,
    });

    const handleChange = (type: 'from' | 'to', newValue: string): void => {
        const parsedNewValue = newValue === '' ? 0 : parseInt(newValue);
        const newValueObject: ValueObjectType = {
            ...value,
            [type]: parsedNewValue,
        };

        setValue(newValueObject);
        debouncedChangeHandler(newValueObject);
    };

    const updateExternalFilter = useCallback(
        (value: ValueObjectType): void => {
            handleAddActiveFilter({ ...filter, isActive: true, value });
        },
        [filter, handleAddActiveFilter]
    );

    const debouncedChangeHandler = useMemo(
        () => debounce(updateExternalFilter, 600),
        [updateExternalFilter]
    );

    return (
        <div className={filterGroupGwp.join(' ')}>
            <div className={styles.inputHeader}>
                <Checkbox
                    onClick={(): void =>
                        filter.isActive
                            ? handleRemoveActiveFilter(filter.id)
                            : handleAddActiveFilter({
                                  ...filter,
                                  value: {
                                      from: filter.value?.from ?? 0,
                                      to: filter.value?.to ?? 99,
                                  },
                              })
                    }
                    checked={filter.isActive}
                />
                <span className={styles.title}>{filter.name}</span>
            </div>
            <div className={styles.inputFields}>
                <TextField
                    id="dataset-name"
                    className="input-field"
                    label={'From GWP units CO₂'}
                    variant="outlined"
                    type="number"
                    value={value.from}
                    onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                        handleChange('from', event.target.value)
                    }
                />
                <TextField
                    id="dataset-name"
                    className="input-field"
                    label={'To GWP units CO₂'}
                    variant="outlined"
                    type="number"
                    value={value.to}
                    onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                        handleChange('to', event.target.value)
                    }
                />
            </div>
        </div>
    );
};

type FilterFactoryProps = {
    filter: EcoSearchTypes.FilterType;
    handleAddActiveFilter: (newFilter: EcoSearchTypes.FilterType) => void;
    handleRemoveActiveFilter: (filterId: string) => void;
};

export const FilterFactory = ({
    filter,
    handleAddActiveFilter,
    handleRemoveActiveFilter,
}: FilterFactoryProps): ReactElement | null => {
    switch (filter.type) {
        case 'Boolean':
            return (
                <BooleanSelector
                    filter={filter}
                    handleAddActiveFilter={handleAddActiveFilter}
                    handleRemoveActiveFilter={handleRemoveActiveFilter}
                />
            );
        case 'Range':
            return (
                <RangeSelector
                    filter={filter}
                    handleAddActiveFilter={handleAddActiveFilter}
                    handleRemoveActiveFilter={handleRemoveActiveFilter}
                />
            );
        case 'Int':
            //Not yet implemented as we don't get one from backend, nor have a design for it
            return null;
    }
    return null;
};
