import { useMemo } from 'react';

import { connectionsApi } from '@xeris/pages/admin/api';
import {
    type ApiBrandAccessRule,
    type ApiBrandGroupMarketAccessRule,
    type ApiBrandProductAccessRule,
    type ApiMarketAccessRule,
    type ApiOrganizationAccessRule,
    type ApiProductGroupAccessRule,
} from '@xeris/types/graphql';

export type DataEntry = {
    id: string;
    name: string;
    label: string;
};

export type FormInputData = {
    exportConfigList: DataEntry[];
    marketList: DataEntry[];
    brandList: DataEntry[];
    rules: (
        | ApiBrandAccessRule
        | ApiProductGroupAccessRule
        | ApiMarketAccessRule
        | ApiOrganizationAccessRule
        | ApiBrandGroupMarketAccessRule
        | ApiBrandProductAccessRule
    )[];
};

export const useGetFormData = (
    producerId: string | null | undefined,
    consumerId: string | null | undefined,
    connectionType: string,
    isEditing: boolean
): FormInputData & { isFetching: boolean } => {
    const {
        data: connectionFormData,
        isFetching,
        isError,
    } = connectionsApi.useGetConnectionFormDataQuery(
        {
            organizationId: producerId ?? null,
            partnerType:
                connectionType === 'channels' ? 'CONSUMER' : 'PRODUCER',
            isCreatingChannel: connectionType === 'channels',
            tradingPartnerId: consumerId ?? null,
            isEditing: isEditing,
        },
        { skip: !producerId || (isEditing && !consumerId) }
    );

    return useMemo(() => {
        const exportConfigList =
            connectionFormData?.exportConfigs?.map((config) => ({
                ...config,
                label: config.name,
            })) ?? [];

        const brandList =
            connectionFormData?.organization?.brands.map((brand) => ({
                ...brand,
                label: brand.name,
            })) ?? [];

        const marketList =
            connectionFormData?.organization?.markets?.map((market) => ({
                ...market,
                label: market.name,
            })) ?? [];

        const rules = connectionFormData?.accessPolicy?.rules ?? [];

        return {
            exportConfigList,
            brandList,
            marketList,
            rules,
            isFetching,
            isError,
        };
    }, [connectionFormData, isFetching, isError]);
};
