import { type ReactElement, type ReactNode } from 'react';

import { Dialog, DialogContent } from '@xeris/components';
import { Footer } from '@xeris/pages/product/ProductDataSelector/Footer/Footer';
import { type HandleAddToDatasetData } from '@xeris/pages/product/ProductDataSelector/types';
import { type NewDataset } from '@xeris/pages/product/types';

import Header from './Header';

type DialogWrapperProps = {
    children?: ReactNode;
    isDialogOpen?: boolean;
    handleAddToDataset: (data: HandleAddToDatasetData) => void;
    handleClose: () => void;
    newDataset: NewDataset;
    handleSetNewDataset: (newDataset: NewDataset) => void;
    activeDatasetName: string | undefined;
    setActiveDatasetId: (datasetId: string | null) => void;
    activeDatasetId: string | null;
    isError: boolean;
};

const DialogWrapper = ({
    children,
    isDialogOpen,
    handleAddToDataset,
    handleClose,
    newDataset,
    handleSetNewDataset,
    activeDatasetName,
    setActiveDatasetId,
    activeDatasetId,
    isError,
}: DialogWrapperProps): ReactElement => {
    return (
        <Dialog
            open={isDialogOpen ?? false}
            onClose={handleClose}
            fullWidth
            maxWidth={'xl'}
            onClick={(event) => event.stopPropagation()}
        >
            <Header handleClose={handleClose} />
            <DialogContent
                dividers
                sx={{ 'padding': 0, '& > div': { maxHeight: '65vh' } }}
            >
                {children}
            </DialogContent>
            <Footer
                handleAddToDataset={handleAddToDataset}
                handleClose={handleClose}
                newDataset={newDataset}
                handleSetNewDataset={handleSetNewDataset}
                activeDatasetName={activeDatasetName}
                setActiveDatasetId={setActiveDatasetId}
                activeDatasetId={activeDatasetId}
                isInlineDialog={false}
                isError={isError}
            />
        </Dialog>
    );
};

export default DialogWrapper;
