import { type ReactElement } from 'react';

import { Box, Skeleton } from '@mui/material';

type SkeletonLoadProps = {
    numberOfProducts?: number;
};

const SkeletonLoad = ({
    numberOfProducts = 1,
}: SkeletonLoadProps): ReactElement => {
    const productPlaceholderList = Array.from(Array(numberOfProducts).keys());

    return (
        <div>
            {productPlaceholderList.length > 1 && (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        borderBottom: 1,
                        borderColor: 'divider',
                        gap: 1,
                    }}
                >
                    {productPlaceholderList.map((index) => (
                        <Skeleton key={index} width={86} height={102} />
                    ))}
                </Box>
            )}

            <Box sx={{ margin: '34px', gap: 1 }}>
                <Skeleton height={84} />
                <Skeleton height={84} />
                <Skeleton height={84} />
            </Box>
        </div>
    );
};
export default SkeletonLoad;
