import { type ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button, Container, Grid, Skeleton, Typography } from '@mui/material';

import {
    ArrowLeftIcon,
    ErrorPage,
    type TabProviderTabType,
    TabsProvider,
} from '@xeris/components';
import {
    fileDataTypes,
    getCollapsePanelListId,
} from '@xeris/pages/product/Common/Datasheet/utilities';
import { settingsSelectors } from '@xeris/pages/product/reducers/settingsSlice';
import { type MasterProduct, type Product } from '@xeris/pages/product/types';
import { isMasterProduct } from '@xeris/pages/product/utilities';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';
import { getDateAndTime } from '@xeris/utilities';
import { addPanelList } from '@xeris/utilities/reducers/collapse';

import ProductEntityCard from '../ProductEntityCard/ProductEntityCard';

import ProductData from './ProductData/ProductData';
import SourceData from './SourceData/SourceData';
import ProductVariants from './ProductVariants';
import RelatedProductEntities from './RelatedProductEntities';

import styles from './Datasheet.module.scss';

const SkeletonLoad = (): ReactElement => {
    return (
        <div className={styles.skeletonLoad}>
            <div className={styles.productCard}>
                <div className={styles.title}>
                    <Skeleton height={42} />
                </div>
                <div>
                    <Skeleton height={495} />
                    <Skeleton height={800} />
                </div>
            </div>
        </div>
    );
};

type DatasheetProps = {
    product?: Product<
        | 'name'
        | 'images'
        | 'gtin'
        | 'brand'
        | 'price'
        | 'prices'
        | 'masterProduct'
        | 'lastUpdated'
        | 'productData'
        | 'relatedProducts'
    > | null;
    masterProduct?: MasterProduct<
        | 'name'
        | 'images'
        | 'brand'
        | 'price'
        | 'prices'
        | 'lastUpdated'
        | 'isConfigurable'
        | 'products'
        | 'productData'
        | 'relatedMasterProducts'
    > | null;
    isLoading: boolean;
    isError: boolean;
    refetch: () => void;
};

const Datasheet = ({
    product,
    masterProduct,
    isLoading,
    isError,
    refetch,
}: DatasheetProps): ReactElement => {
    const { t } = useTranslation('product');
    const productEntity = product ?? masterProduct ?? undefined;

    const dispatch = useAppDispatch();

    const showMissingProductData = useAppSelector(
        settingsSelectors.selectShowMissingProductData
    );

    const collapse = useAppSelector((state) => state.collapse);

    const panelListId = productEntity
        ? getCollapsePanelListId(productEntity.id, productEntity.name)
        : '';

    useEffect(() => {
        if (productEntity && collapse && !collapse[panelListId]) {
            dispatch(
                addPanelList({
                    listId: panelListId,
                    panelIds: (productEntity.productData ?? []).map(
                        (data) => data.type
                    ),
                    collapsed: false,
                })
            );
        }
    });

    if (isLoading) {
        return <SkeletonLoad />;
    }

    if (isError) {
        return (
            <ErrorPage
                title={t('datasheet.errors.anErrorOccurred')}
                onClick={() => refetch()}
                actionText={t('datasheet.errors.tryAgain')}
            />
        );
    }

    if (!productEntity) {
        return (
            <ErrorPage
                title={t('datasheet.errors.notFound')}
                description={t('datasheet.errors.notFoundDescription')}
            />
        );
    }

    const contentData = productEntity.productData.filter(
        (data) => !fileDataTypes.includes(data.type)
    );

    const fileData = productEntity.productData.filter((data) =>
        fileDataTypes.includes(data.type)
    );

    const fileDataCount = fileData.reduce((sum, data) => sum + data.count, 0);

    const tabList: TabProviderTabType[] = [
        {
            id: 'content',
            Label: <>{t('datasheet.datasheet')}</>,
            Component: (
                <ProductData
                    productEntity={productEntity}
                    productData={contentData}
                />
            ),
        },
    ];

    if (fileDataCount > 0 || showMissingProductData) {
        tabList.push({
            id: 'files',
            Label: <>{t('datasheet.mediaAndFiles')}</>,
            count: fileDataCount,
            Component: (
                <ProductData
                    productEntity={productEntity}
                    productData={fileData}
                />
            ),
        });
    }

    if (isMasterProduct(productEntity) && !productEntity.isConfigurable) {
        tabList.push({
            id: 'variants',
            Label: <>{t('datasheet.variants')}</>,
            Component: <ProductVariants masterProductId={productEntity.id} />,
            count: productEntity.products.length,
        });
    }

    const relatedProductEntitiesCounter =
        product?.relatedProducts.length ??
        masterProduct?.relatedMasterProducts.length ??
        0;

    if (relatedProductEntitiesCounter > 0) {
        tabList.push({
            id: 'relatedProducts',
            Label: <>{t('datasheet.relatedProducts')}</>,
            Component: (
                <RelatedProductEntities
                    productEntityId={productEntity.id}
                    isMasterProduct={isMasterProduct(productEntity)}
                />
            ),
            count: relatedProductEntitiesCounter,
        });
    }
    return (
        <>
            <Grid container spacing={3}>
                <Grid
                    item
                    xs={12}
                    md={4}
                    lg={3}
                    className={styles.productEntityCardWrapper}
                >
                    <div className={styles.productEntityCard}>
                        <ProductEntityCard
                            product={product}
                            masterProduct={masterProduct}
                            isActionButtonsAlwaysVisible
                        />
                    </div>
                </Grid>
                <Grid item md={8} lg={9}>
                    <Container
                        disableGutters
                        sx={{
                            backgroundColor: 'background.paper',
                            paddingBlock: 1,
                        }}
                    >
                        {isMasterProduct(productEntity) ? (
                            <Button
                                color={'secondary'}
                                startIcon={<ArrowLeftIcon />}
                                component={Link}
                                to={`/Products/${productEntity.brand.id}/`}
                                sx={{
                                    marginLeft: '-5px',
                                    lineHeight: '18px',
                                    marginBottom: 1,
                                    textTransform: 'capitalize',
                                }}
                                size={'small'}
                            >
                                {t('datasheet.brandProducts', {
                                    brand: productEntity.brand.name,
                                })}
                            </Button>
                        ) : (
                            <Button
                                color={'secondary'}
                                startIcon={<ArrowLeftIcon />}
                                component={Link}
                                to={`/Products/${productEntity.brand.id}/MasterProducts/${productEntity.masterProduct.id}`}
                                sx={{
                                    marginLeft: '-5px',
                                    lineHeight: '18px',
                                    marginBottom: 1,
                                }}
                                size={'small'}
                            >
                                {t('productList.back')}
                            </Button>
                        )}
                        <Typography variant={'h1'}>
                            {productEntity.name}
                        </Typography>

                        <TabsProvider
                            tabList={tabList}
                            toolbar={
                                <Typography
                                    variant={'caption'}
                                    noWrap
                                    sx={{ mr: 1 }}
                                >
                                    {t('datasheet.lastUpdated', {
                                        time: getDateAndTime(
                                            productEntity.lastUpdated
                                        ),
                                    })}
                                </Typography>
                            }
                        />
                    </Container>
                </Grid>
            </Grid>
            <SourceData productEntity={productEntity} />
        </>
    );
};

export default Datasheet;
