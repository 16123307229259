import { type ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    Alert,
    DialogContent,
    Drawer,
    InputAdornment,
    Slide,
    Snackbar,
} from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
    Button,
    DialogActions,
    DialogTitle,
    LockPrivateIcon,
} from '@xeris/components';
import { TextInput } from '@xeris/components/forms';
import { brandApi } from '@xeris/pages/admin/api';
import { useActiveOrganizationId } from '@xeris/pages/admin/hooks';

const editBrandSchema = yup
    .object({
        id: yup.string().required('required'),
        name: yup.string().required('required'),
    })
    .required();

type EditBrandFormData = yup.InferType<typeof editBrandSchema>;

type EditBrandProps = {
    isOpen: boolean;
    brandData?: EditBrandFormData | null;
    handleClose: () => void;
};

const EditBrand = ({
    isOpen,
    brandData,
    handleClose,
}: EditBrandProps): ReactElement | null => {
    const { t } = useTranslation('administration');

    const [isSaveErrorOpen, setIsSaveErrorOpen] = useState(false);
    const [isSubmitBrandSuccess, setIsSubmitBrandSuccess] = useState(false);

    const activeOrganizationId = useActiveOrganizationId();

    const [editBrandMutation, editBrandResult] =
        brandApi.useEditBrandMutation();
    const { handleSubmit, control, reset } = useForm<EditBrandFormData>({
        resolver: yupResolver(editBrandSchema),
        defaultValues: {
            id: brandData?.id ?? '',
            name: brandData?.name ?? '',
        },
    });

    const handleSubmitBrand = async (
        editBrandData: EditBrandFormData
    ): Promise<void> => {
        try {
            await editBrandMutation(editBrandData).unwrap();
            reset(editBrandData);
            setIsSubmitBrandSuccess(true);
        } catch (error) {
            reset();
            setIsSaveErrorOpen(true);
        } finally {
            handleClose();
        }
    };

    useEffect(() => {
        reset(brandData ?? undefined);
    }, [reset, brandData]);

    if (!activeOrganizationId) {
        return null;
    }

    return (
        <>
            <Drawer
                open={isOpen}
                onClose={handleClose}
                PaperProps={{
                    'component': 'form',
                    'aria-label': t(`brand.editBrand.editBrand`),
                }}
                onSubmit={handleSubmit(handleSubmitBrand)}
            >
                <>
                    <DialogTitle>{t(`brand.editBrand.editBrand`)} </DialogTitle>
                    <DialogContent dividers sx={{ maxWidth: '500px' }}>
                        <TextInput
                            label={t('brand.editBrand.id')}
                            fieldName={'id'}
                            control={control}
                            required
                            disabled
                            showError
                            placeholder={t('brand.editBrand.idPlaceholder')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <LockPrivateIcon fontSize={'small'} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextInput
                            label={t('brand.editBrand.name')}
                            fieldName={'name'}
                            control={control}
                            required
                            showError
                            autoFocus
                            placeholder={t('brand.editBrand.namePlaceholder')}
                        />
                    </DialogContent>
                    <DialogActions>
                        {handleClose && (
                            <Button
                                variant="textNeutral"
                                onClick={(): void => {
                                    reset();
                                    handleClose();
                                }}
                            >
                                {t('common.cancel')}
                            </Button>
                        )}
                        <Button
                            type={'submit'}
                            disabled={editBrandResult.isLoading}
                        >
                            {t('brand.common.save')}
                        </Button>
                    </DialogActions>
                </>
            </Drawer>

            <Snackbar
                open={isSaveErrorOpen && !!editBrandResult.error}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                TransitionComponent={Slide}
            >
                <Alert
                    severity="error"
                    elevation={2}
                    onClose={() => setIsSaveErrorOpen(false)}
                >
                    {t('brand.editBrand.errorOnEdit')}
                </Alert>
            </Snackbar>
            <Snackbar
                open={isSubmitBrandSuccess}
                autoHideDuration={4000}
                onClose={() => setIsSubmitBrandSuccess(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                TransitionComponent={Slide}
            >
                <Alert severity="success" elevation={2}>
                    {t('brand.editBrand.editBrandSuccess')}
                </Alert>
            </Snackbar>
        </>
    );
};

export default EditBrand;
