import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useWhoami } from '@xeris/hooks';
import {
    type Connection,
    type ConnectionsTabVariantType,
    type UiConnectionType,
} from '@xeris/pages/admin/Connections/types/connectionsTypes';
import { useActiveOrganization } from '@xeris/pages/admin/hooks';

import NewConnection from '../../common/NewConnection/NewConnection';

import styles from './ListHeader.module.scss';

type ListHeaderProps = {
    connectionType: ConnectionsTabVariantType;
    uiConnectionType: UiConnectionType;
    connectionCount: number;
    connectionList: Connection[];
};

const ListHeader = ({
    connectionType,
    uiConnectionType,
    connectionCount,
    connectionList,
}: ListHeaderProps): ReactElement | null => {
    const { t } = useTranslation('administration');

    const { isSuperAdmin } = useWhoami();

    const activeOrganization = useActiveOrganization();

    if (connectionCount === 0) return null;

    return (
        <div className={styles.header}>
            <div>
                {connectionType === 'channels'
                    ? t('connections.listOfConnectedTradingPartners', {
                          organizationName: activeOrganization?.name,
                      })
                    : t('connections.connectedTradingPartners')}
            </div>
            {(isSuperAdmin || connectionType === 'channels') && (
                <NewConnection
                    className={styles.newButton}
                    connectionType={connectionType}
                    uiConnectionType={uiConnectionType}
                    connectionList={connectionList}
                />
            )}
        </div>
    );
};

export default ListHeader;
