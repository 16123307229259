import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ListItemIcon, ListItemText } from '@mui/material';

import { type TFunction } from 'i18next';

import { DeleteIcon, EditIcon, SelectMenu } from '@xeris/components';
import type { SelectMenuItemType } from '@xeris/components/SelectMenu/SelectMenu';

const getActionMenuList = (
    t: TFunction<'administration'>,
    handleEditConnection: () => void,
    handleDeleteConnection: () => void,
    isProducer: boolean
): SelectMenuItemType<string>[] => {
    const selectMenuItems = [
        {
            key: 'deleteConnection',
            value: 'deleteConnection',
            handleClick: handleDeleteConnection,
            content: (
                <>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={t('connections.deleteConnection')} />
                </>
            ),
        },
    ];

    if (isProducer) {
        selectMenuItems.unshift({
            key: 'editConnection',
            value: 'editConnection',
            handleClick: handleEditConnection,
            content: (
                <>
                    <ListItemIcon>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={t('connections.editConnection')} />
                </>
            ),
        });
    }

    return selectMenuItems;
};

type ActionMenuProps = {
    connectionId: string;
    tradingPartner: string;
    setSelectedConnection: (connectionId: string | null) => void;
    setSelectedDeleteConnection: (connectionId: string | undefined) => void;
    isProducer: boolean;
};

const ActionMenu = ({
    connectionId,
    setSelectedConnection,
    setSelectedDeleteConnection,
    tradingPartner,
    isProducer,
}: ActionMenuProps): ReactElement | null => {
    const { t } = useTranslation('administration');

    const handleEditConnection = (): void => {
        setSelectedConnection(connectionId);
    };

    const handleDeleteConnection = (): void => {
        setSelectedDeleteConnection(connectionId);
    };

    return (
        <SelectMenu
            menuItemList={getActionMenuList(
                t,
                handleEditConnection,
                handleDeleteConnection,
                isProducer
            )}
            dataTestId="channel-menu-button"
            ariaLabel={t('connections.openActionsMenuFor', {
                tradingPartner: tradingPartner,
            })}
        />
    );
};

export default ActionMenu;
