import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { CloseIcon, IconButton, Image, PreviewButton } from '@xeris/components';
import {
    type BasicFeature,
    type BasicOption,
} from '@xeris/pages/product/types';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';

import { Price } from '../../../Common';
import {
    selectOptions,
    selectPreview,
    variantGeneratorSelectors,
} from '../../../reducer';

import styles from './BasicPanel.module.scss';

type OptionCardProps = {
    option: BasicOption;
    featureId: string;
};

type BasicPanelProps = {
    feature: BasicFeature;
};

const OptionCard = ({
    featureId,
    option,
}: OptionCardProps): ReactElement | null => {
    const { t } = useTranslation('product');
    const dispatch = useAppDispatch();

    const { isSelected, isPreviewed } = useAppSelector((state) =>
        variantGeneratorSelectors.optionStatus(state, featureId, option.id)
    );

    if (!isSelected) {
        return null;
    }

    const handleSelectPreview = (): void => {
        dispatch(
            selectPreview({
                optionId: option.id,
                featureId: featureId,
            })
        );
    };

    const handleDeselectOption = (): void => {
        dispatch(
            selectOptions({
                featureId: featureId,
                optionIds: [option.id],
                newValue: false,
            })
        );
    };

    return (
        <Box
            className={styles.optionCard}
            sx={{ '&:hover ': { '.preview': { opacity: 1 } } }}
        >
            <div className={styles.option}>
                <PreviewButton
                    size={'small'}
                    className={'preview'}
                    sx={{ opacity: isPreviewed ? 1 : 0 }}
                    isPreviewActive={isPreviewed}
                    onSelectPreview={handleSelectPreview}
                />
                <Image
                    alt={t('variantGenerator.previewOf', {
                        optionName: option.name,
                    })}
                    src={option.image ?? undefined}
                />
                <span className={styles.name}>{option.name}</span>
            </div>
            <div>
                <Price price={option.price} />
                <IconButton onClick={handleDeselectOption} size="large">
                    <CloseIcon color="icons" fontSize="small" />
                </IconButton>
            </div>
        </Box>
    );
};

const BasicPanel = ({ feature }: BasicPanelProps): ReactElement | null => {
    const { isSelected } = useAppSelector((state) =>
        variantGeneratorSelectors.featureStatus(state, feature.id)
    );

    if (!isSelected || !feature.options || feature.options.length === 0) {
        return null;
    }

    return (
        <div className={styles.basicPanel}>
            <div className={styles.featureName}>{feature.name}</div>
            {feature.options.map(
                (option): ReactElement => (
                    <OptionCard
                        key={option.id}
                        featureId={feature.id}
                        option={option}
                    />
                )
            )}
        </div>
    );
};

export default BasicPanel;
