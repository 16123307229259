import { gql } from 'graphql-request';

export const getSegmentsQuery = gql`
    query GetSegments {
        eco {
            categories(type: gpc_segment) {
                id
                name
                type
                children {
                    type
                    id
                    name
                    children {
                        type
                        id
                        name
                        children {
                            type
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

export const getFilterGroupsQuery = gql`
    query GetFilterGroups {
        eco {
            filterGroups: filter_groups {
                name
                filters {
                    id
                    type
                    name
                    description
                }
            }
        }
    }
`;

const productDataQueryFields = gql`
    fragment ProductDataQueryFields on ProductData {
        __typename
        id
        name
        brand {
            id
            name
        }
        price {
            value
            currency
            priceUnit: price_unit
        }
        description
        documents {
            id
            mimeType: mime_type
            filterName: file_name
            title
            type {
                id
                label
            }
            url
        }
        images {
            id
            mimeType: mime_type
            title
            type
            objectFit: object_fit
            url: version(size_format: THUMBNAIL)
            id
        }
        models {
            id
            fileName: file_name
            type
            title
            url
        }
        lastUpdated: last_updated
        ... on MasterProduct {
            isConfigurable: is_configurable
            products {
                id
            }
        }
        ... on Product {
            gtin
            distinguishingFeatures: distinguishing_features {
                name
                type
                value
            }
            masterProduct: master_product {
                id
                name
            }
        }
    }
`;

export const getFilteredProductsQuery = gql`
    query GetFilteredProducts(
        $type: CategoryType!
        $categoryId: String!
        $filters: [EcoFilterInput!]
    ) {
        eco {
            query(type: $type, category_id: $categoryId, filters: $filters) {
                category {
                    type
                    id
                    name
                }
                totalProducts: total_products
                totalProducers: total_producers
                matchingProducts: matching_products
                matchingProducers: matching_producers
                filterCounts: filter_counts {
                    filterId: filter_id
                    count
                }
                subCategories: sub_categories {
                    products {
                        ...ProductDataQueryFields
                    }
                    subCategories: sub_categories {
                        products {
                            ...ProductDataQueryFields
                        }
                        subCategories: sub_categories {
                            products {
                                ...ProductDataQueryFields
                            }
                        }
                    }
                }
                products {
                    ...ProductDataQueryFields
                }
            }
        }
    }
    ${productDataQueryFields}
`;
