import { type ReactElement, type ReactNode } from 'react';
import { Route, Routes } from 'react-router-dom';

import Dataset from './Dataset/Dataset';
import DatasetList from './DatasetList/DatasetList';

import './index.scss';

type PageWrapperProps = {
    children?: ReactNode;
};

const PageWrapper = ({ children }: PageWrapperProps): ReactElement => {
    return (
        <div className="dataset-page-wrapper main-content-max-width">
            <div className="dataset-subpage-wrapper">{children}</div>
        </div>
    );
};

const DatasetPage = (): ReactElement => {
    return (
        <PageWrapper>
            <Routes>
                <Route
                    path={'ProductList/:datasetId'}
                    element={<Dataset />}
                ></Route>
                <Route path={'*'} element={<DatasetList />} />
            </Routes>
        </PageWrapper>
    );
};

export default DatasetPage;
