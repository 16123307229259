import { type FormData } from '../connectionSchema';

type Action = 'ALLOW' | 'DENY';

const noRule = {
    groupRule: null,
    brandRule: null,
    organizationRule: null,
    marketRule: null,
    brandGroupMarketRule: null,
    brandProductRule: null,
};

export const convertFormDataToRules = (
    formData: FormData,
    organizationId: string,
    action: Action
): {
    organizationRule: { action: Action; targetOrganization: string } | null;
    brandRule: { action: Action; targetBrand: string } | null;
    marketRule: { action: Action; targetMarket: string } | null;
    groupRule: never | null;
    brandGroupMarketRule: never | null;
    brandProductRule: never | null;
}[] => {
    if (formData.accessRuleType === 'organization') {
        return [
            {
                ...noRule,
                organizationRule: {
                    action,
                    targetOrganization: organizationId,
                },
            },
        ];
    }

    if (formData.accessRuleType === 'brands') {
        return formData.accessToBrands.map((brand) => ({
            ...noRule,
            brandRule: {
                action,
                targetBrand: brand.id,
            },
        }));
    }

    if (formData.accessRuleType === 'markets') {
        return formData.accessToMarkets.map((market) => ({
            ...noRule,
            marketRule: {
                action,
                targetMarket: market.id,
            },
        }));
    }

    return [];
};
