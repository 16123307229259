import { type ReactElement, type ReactNode } from 'react';

import FullscreenDrawer from './FullscreenDrawer';
import InlineDrawer from './InlineDrawer';

export type AlignType = 'right' | 'left';

type DrawerVariantType = 'inline' | 'fullscreen';
export type AnchorType = {
    top?: number;
    bottom?: number;
};

type InsetType = {
    top?: number | string;
    right?: number | string;
    bottom?: number | string;
    left?: number | string;
};

type GetTransitionStylesReturnType = Record<string, InsetType>;

export const getTransitionStyles = (
    align: AlignType,
    rect: DOMRect | null,
    anchor: AnchorType
): GetTransitionStylesReturnType => {
    const defaultStyle = {
        top: rect?.top,
        bottom: 0,
        ...anchor,
    };

    let isIn;
    let isOut;

    if (align === 'right') {
        isIn = { ...defaultStyle, left: rect?.left, right: 0 };
        isOut = { ...defaultStyle, left: '100%', right: '-100%' };
    } else {
        isIn = { ...defaultStyle, left: 0, right: rect?.right };
        isOut = { ...defaultStyle, left: '-100%', right: '100%' };
    }

    return {
        entering: isOut,
        entered: isIn,
        exiting: isIn,
        exited: isOut,
    };
};

const componentLookup = {
    inline: InlineDrawer,
    fullscreen: FullscreenDrawer,
};

const DURATION = 200;

type SlidingDrawerProps = {
    zIndex?: number;
    children: ReactNode;
    align?: AlignType;
    show?: boolean;
    disableScroll?: boolean;
    /**
     * by default the drawer aligns on top where is located, bottom to the end of the window
     * and left and right to the end of the screen depending if align is left or right.
     * The target property can be used to override any of these alignment anchors
     */
    anchor?: AnchorType;
    variant: DrawerVariantType;
    paperProps?: Record<string, unknown>;
    handleClose?: () => void;
};

const SlidingDrawer = ({
    zIndex = 1301,
    align = 'right',
    children,
    anchor = {},
    disableScroll = false,
    show = false,
    variant,
    paperProps,
    handleClose = (): void => {},
}: SlidingDrawerProps): ReactElement => {
    const Component = componentLookup[variant];

    return (
        <Component
            zIndex={zIndex}
            align={align}
            anchor={anchor}
            disableScroll={disableScroll}
            show={show}
            duration={DURATION}
            handleClose={handleClose}
            paperProps={paperProps}
        >
            {children}
        </Component>
    );
};

export default SlidingDrawer;
