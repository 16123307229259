import { gql } from 'graphql-request';

export const connectionFields = gql`
    fragment ConnectionFields on Connection {
        id
        producer {
            id
            name
        }
        consumer {
            id
            name
        }
        status
        accessType: access_type
        created
        lastModified: last_modified
        exports {
            id
            name
            description
        }
    }
`;

export const getConnections = gql`
    query getConnections($organizationId: String) {
        organization(id: $organizationId) {
            id
            feeds {
                ...ConnectionFields
            }
            channels {
                ...ConnectionFields
            }
        }
    }
    ${connectionFields}
`;

export const getConnectionFormData = gql`
    query getConnectionFormData(
        $partnerType: OrganizationType!
        $organizationId: String
        $tradingPartnerId: String
        $isCreatingChannel: Boolean!
        $isEditing: Boolean!
    ) {
        organizations(type: $partnerType) {
            id
            name
        }
        exportConfigs: exports(organization: $organizationId)
            @include(if: $isCreatingChannel) {
            id
            name
        }
        organization(id: $organizationId) @include(if: $isCreatingChannel) {
            markets {
                id
                name
            }
            brands {
                id
                name
            }
        }
        accessPolicy(
            organizationId: $tradingPartnerId
            tradingPartnerId: $organizationId
        ) @include(if: $isEditing) {
            organizationId
            rules {
                __typename
                id
                action
                ... on OrganizationAccessRule {
                    targetOrganization
                }
                ... on BrandAccessRule {
                    targetBrand
                }
                ... on MarketAccessRule {
                    targetMarket
                }
                ... on ProductGroupAccessRule {
                    targetGroup
                }
                ... on BrandGroupMarketAccessRule {
                    targetBrand
                    filterMarkets
                    filterGroups {
                        groupType
                        groups
                    }
                }
                ... on BrandProductAccessRule {
                    targetBrand
                    filterProducts
                }
            }
        }
    }
`;

export const createConnection = gql`
    mutation createConnection(
        $producerId: String!
        $consumerId: String!
        $status: ConnectionStatus!
        $rules: [InputAccessRule!]!
        $hasRules: Boolean!
        $exportIds: [String!]
        $hasExports: Boolean!
    ) {
        connection {
            create_connection(
                producer: $producerId
                consumer: $consumerId
                status: $status
            )
        }
        export @include(if: $hasExports) {
            shareExports(
                producer: $producerId
                consumer: $consumerId
                exportIds: $exportIds
            )
        }
        access @include(if: $hasRules) {
            addRules(organizationId: $consumerId, rules: $rules)
        }
    }
`;

export const updateConnection = gql`
    mutation updateConnection(
        $producerId: String!
        $consumerId: String!
        $exportIds: [String!]!
        $ruleIdsToDelete: [String!]!
        $rules: [InputAccessRule!]!
    ) {
        export {
            shareExports(
                producer: $producerId
                consumer: $consumerId
                exportIds: $exportIds
            )
        }
        delete: access {
            deleteRules(ruleIds: $ruleIdsToDelete)
        }
        add: access {
            addRules(organizationId: $consumerId, rules: $rules)
        }
    }
`;

export const deleteConnection = gql`
    mutation deleteConnection($connectionId: String!) {
        connection {
            delete_connection(id: $connectionId)
        }
    }
`;
