import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Divider,
    FormControl,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
} from '@mui/material';

import { PlusIcon, Typography } from '@xeris/components';
import { useAppSelector } from '@xeris/reducers';
import { datasetSelectors } from '@xeris/selectors';

type NewDatasetProps = {
    name: string;
    showAddDataset: boolean;
};

const NewDataset = ({
    name,
    showAddDataset,
}: NewDatasetProps): ReactElement => {
    const { t } = useTranslation('product');
    const datasetName = name === '' ? t('dataSelector.newDataset') : name;

    if (showAddDataset) {
        return (
            <Typography color={name === '' ? 'primary' : 'secondary'} noWrap>
                {datasetName}
            </Typography>
        );
    }

    return (
        <>
            <ListItemIcon>
                <PlusIcon color={'primary'} />
            </ListItemIcon>
            <ListItemText
                primary={t('dataSelector.newDataset')}
                primaryTypographyProps={{
                    sx: { whiteSpace: 'normal' },
                    noWrap: true,
                }}
            />
        </>
    );
};

export type HandleSetShowAddDatasetType = (showAddDataset: boolean) => void;

type SelectDatasetProps = {
    handleSetShowAddDataset: HandleSetShowAddDatasetType;
    setActiveDatasetId: (datasetId: string | null) => void;
    activeDatasetId: string | null;
    name: string;
    showAddDataset: boolean;
    disabled?: boolean;
};

const SelectDataset = ({
    handleSetShowAddDataset,
    setActiveDatasetId,
    activeDatasetId,
    name,
    showAddDataset,
    disabled,
}: SelectDatasetProps): ReactElement => {
    const { t } = useTranslation('product');

    const datasetList = useAppSelector(datasetSelectors.minimal.selectAll);

    return (
        <FormControl
            sx={{ flexShrink: 0, flexBasis: '250px', maxWidth: '300px' }}
        >
            <InputLabel shrink>{t('common.selectDataset')}</InputLabel>
            <Select
                disabled={disabled}
                label={t('common.selectDataset')}
                value={activeDatasetId ?? 'new'}
                onChange={(event) => {
                    const datasetId = event.target.value;

                    if (datasetId === 'new') {
                        handleSetShowAddDataset(true);
                        setActiveDatasetId(null);
                    } else {
                        handleSetShowAddDataset(false);
                        setActiveDatasetId(datasetId);
                    }
                }}
                variant="outlined"
            >
                <MenuItem key="new" value="new" sx={{ maxWidth: '300px' }}>
                    <NewDataset showAddDataset={showAddDataset} name={name} />
                </MenuItem>
                <Divider />
                {datasetList.map((dataset) => (
                    <MenuItem
                        key={dataset.id}
                        value={dataset.id}
                        sx={{ maxWidth: '300px' }}
                    >
                        <Typography noWrap>{dataset.name}</Typography>
                    </MenuItem>
                ))}
            </Select>{' '}
        </FormControl>
    );
};

export default SelectDataset;
