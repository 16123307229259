import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type ApiGetConfigurableMasterProductQuery } from '@xeris/pages/product/api/masterProductApi/masterProductQueries.gql';

import type { VariantGeneratorSlice } from '../types/variantGeneratorSliceTypes';
import {
    ensureValidPreviewSelection,
    ensureValidSelection,
    getFeatureList,
    getSelectionFromFeatures,
    updateFeatureSelection,
} from '../utilities';

const initialState: VariantGeneratorSlice = {
    isInitialized: false,

    featureSelection: {},
    previewSelection: {},
    featureVisibility: {},
    previewVisibility: {},

    featureFilters: {},
    materialInfoBoxMasterProductId: null,

    features: [],
    featureConfiguration: null,
};

const featureSlice = createSlice({
    name: 'variantGeneratorSlice',
    initialState,
    reducers: {
        initializeVariantGeneratorSlice(
            state,
            action: PayloadAction<{
                masterProduct: ApiGetConfigurableMasterProductQuery['master_product'];
                configurationSetId?: string;
            }>
        ) {
            const { masterProduct, configurationSetId } = action.payload;

            state.featureConfiguration = null;

            if (masterProduct?.featureConfiguration) {
                const featureConfiguration = {
                    base_price: masterProduct.featureConfiguration.basePrice,
                    base_prices: masterProduct.featureConfiguration.basePrices,
                    conditional_price:
                        masterProduct.featureConfiguration.conditional_price,
                    conditional_prices:
                        masterProduct.featureConfiguration.conditional_prices,
                    feature_clusters:
                        masterProduct.featureConfiguration.featureClusters,
                    feature_groups:
                        masterProduct.featureConfiguration.featureGroups,
                };

                const features = getFeatureList(
                    masterProduct.featureConfiguration.featureGroups
                );

                state.features = features;

                const {
                    selectedOptions: previewSelection,
                    visibility: previewVisibility,
                } = ensureValidPreviewSelection(featureConfiguration, features);

                state.previewSelection = previewSelection;
                state.previewVisibility = previewVisibility;

                const configurationSet =
                    masterProduct.selectedData?.configurationSets.find(
                        ({ id }) => id === configurationSetId
                    );

                const {
                    selectedOptions: featureSelection,
                    visibility: featureVisibility,
                } = ensureValidSelection(
                    featureConfiguration,
                    getSelectionFromFeatures(configurationSet?.features ?? [])
                );

                state.featureSelection = featureSelection;
                state.featureVisibility = featureVisibility;

                state.featureConfiguration = featureConfiguration;
            }

            state.isInitialized = true;

            return state;
        },
        resetVariantGeneratorSlice(state) {
            state.isInitialized = false;

            state.featureSelection = {};
            state.previewSelection = {};
            state.featureVisibility = {};
            state.previewVisibility = {};

            state.featureFilters = {};
            state.materialInfoBoxMasterProductId = null;

            state.features = [];
            state.featureConfiguration = null;

            return state;
        },
        selectOptions(
            state,
            action: PayloadAction<{
                featureId: string;
                optionIds: string[];
                newValue: boolean;
            }>
        ) {
            const { featureId, optionIds, newValue } = action.payload;

            if (!state.featureConfiguration) {
                return;
            }

            const {
                selectedOptions: featureSelection,
                visibility: featureVisibility,
            } = ensureValidSelection(
                state.featureConfiguration,
                updateFeatureSelection(
                    state.featureSelection,
                    featureId,
                    optionIds,
                    newValue
                )
            );

            state.featureSelection = featureSelection;
            state.featureVisibility = featureVisibility;
        },
        selectPreview(
            state,
            action: PayloadAction<{ featureId: string; optionId: string }>
        ) {
            const { featureId, optionId } = action.payload;

            if (!state.featureConfiguration) {
                return;
            }

            const {
                selectedOptions: previewSelection,
                visibility: previewVisibility,
            } = ensureValidPreviewSelection(
                state.featureConfiguration,
                state.features,
                { ...state.previewSelection, [featureId]: optionId }
            );

            state.previewSelection = previewSelection;
            state.previewVisibility = previewVisibility;
        },
        setMaterialInfo(
            state,
            action: PayloadAction<{ masterProductId: string | null }>
        ) {
            state.materialInfoBoxMasterProductId =
                action.payload.masterProductId;
        },
        setMaterialFilter(
            state,
            action: PayloadAction<{ featureId: string; filter: string }>
        ) {
            const { featureId, filter } = action.payload;

            state.featureFilters[featureId] = filter;
        },
    },
});

export const {
    initializeVariantGeneratorSlice,
    resetVariantGeneratorSlice,
    selectOptions,
    selectPreview,
    setMaterialInfo,
    setMaterialFilter,
} = featureSlice.actions;

export default featureSlice.reducer;
