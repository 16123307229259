import { type ReactElement, useEffect, useRef, useState } from 'react';

import { TextField, type TextFieldProps } from '@mui/material';

import { useFocus } from '@xeris/hooks';
import { useAppSelector } from '@xeris/reducers';
import { datasetSelectors } from '@xeris/selectors';

import styles from './CreateNewDataset.module.scss';

export type NewDatasetType = {
    name: string;
    description: string;
    isNameValid: boolean;
    attemptedSubmitted: boolean;
    showForm: boolean;
};

export type HandleSetNewDatasetType = (newDataset: NewDatasetType) => void;

type CreateNewDatasetProps = {
    newDataset: NewDatasetType;
    handleSetNewDataset: HandleSetNewDatasetType;
};

type ValidateDatasetNameType = (props: {
    newDatasetName: string;
    showForm: boolean;
    datasetNames: string[];
}) => {
    isNameValid: boolean;
    errorMessage: string;
};

const validateDatasetName: ValidateDatasetNameType = ({
    newDatasetName,
    showForm,
    datasetNames,
}) => {
    if (showForm && newDatasetName === '') {
        return {
            isNameValid: false,
            errorMessage: 'Please add a name to the dataset',
        };
    }

    const isNameUsed = datasetNames.includes(newDatasetName);

    if (showForm && isNameUsed) {
        return {
            isNameValid: false,
            errorMessage: 'Name cannot be same as preexisting datasets name',
        };
    }

    return { isNameValid: true, errorMessage: '' };
};

const CreateNewDataset = ({
    newDataset,
    handleSetNewDataset,
}: CreateNewDatasetProps): ReactElement | null => {
    const [inputRef, setInputFocus] = useFocus();

    const scrollRef = useRef<HTMLDivElement>(null);
    const scrollToBottom = (): void => {
        scrollRef.current?.scrollIntoView(true);
    };

    const datasetNames = useAppSelector(
        datasetSelectors.minimal.selectDatasetNameList
    );

    const [errorMessage, setErrorMessage] = useState('');

    const setName: TextFieldProps['onChange'] = (event) => {
        const newDatasetName = event.target.value;

        const { isNameValid, errorMessage } = validateDatasetName({
            newDatasetName,
            showForm: newDataset.showForm,
            datasetNames,
        });

        setErrorMessage(errorMessage);

        handleSetNewDataset({
            ...newDataset,
            attemptedSubmitted: false,
            isNameValid,
            name: newDatasetName,
        });
    };

    const setDescription: TextFieldProps['onChange'] = (event) => {
        handleSetNewDataset({
            ...newDataset,
            description: event.target.value,
        });
    };

    useEffect(() => {
        scrollToBottom();
        setInputFocus();
    }, [newDataset.showForm, setInputFocus]);

    if (!newDataset.showForm) {
        return null;
    }

    return (
        <div className={styles.createNewDataset}>
            <div className={styles.header}>
                <span>New Dataset</span>
            </div>
            <div className={styles.content}>
                <TextField
                    id="dataset-name"
                    className="input-field"
                    label="Dataset Name"
                    variant="outlined"
                    value={newDataset.name}
                    onChange={setName}
                    inputRef={inputRef}
                    error={
                        newDataset.attemptedSubmitted && !newDataset.isNameValid
                    }
                    helperText={
                        newDataset.attemptedSubmitted ? errorMessage : ''
                    }
                />
                <TextField
                    id="dataset-description"
                    className="input-field"
                    label="Description (Optional)"
                    multiline
                    rows={4}
                    variant="outlined"
                    value={newDataset.description}
                    onChange={setDescription}
                />
            </div>
            <div ref={scrollRef} />
        </div>
    );
};

export default CreateNewDataset;
