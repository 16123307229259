import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { AvatarGroup, SvgIcon } from '@mui/material';

import { type TFunction } from 'i18next';

import {
    AccountMultipleIcon,
    EditIcon,
    EyeIcon,
    LockPrivateIcon,
    Tooltip,
    UserAccountAvatar,
} from '@xeris/components';

import {
    type AccessStatus,
    type DatasetSharedWith,
    type SharingPrivilege,
} from '../../types/datasetTypes';
import { getAccessStatus } from '../../utilities';

import styles from '../DatasetList.module.scss';

export const accessIconLookup: Record<AccessStatus, ReactElement> = {
    READ: <EyeIcon />,
    WRITE: <EditIcon />,
    PRIVATE: <LockPrivateIcon />,
    SHARED: <AccountMultipleIcon />,
};

export const getAccessLabel = (
    t: TFunction<'dataset'>,
    accessType: AccessStatus
): string =>
    ({
        READ: t('datasetList.canView'),
        WRITE: t('datasetList.canEdit'),
        PRIVATE: t('datasetList.private'),
        SHARED: t('datasetList.sharedDataset'),
    })[accessType];

type AccessListProps = {
    dataset: {
        privilege: SharingPrivilege;
        sharedWith: DatasetSharedWith[];
    };
};

const AccessList = ({ dataset }: AccessListProps): ReactElement => {
    const { t } = useTranslation('dataset');

    const accessStatus = getAccessStatus(dataset);

    const AccessIcon = accessIconLookup[accessStatus];

    const avatarList = dataset.sharedWith;

    return (
        <div className={styles.datasetAccess}>
            <Tooltip title={getAccessLabel(t, accessStatus)}>
                <SvgIcon>{AccessIcon}</SvgIcon>
            </Tooltip>

            <AvatarGroup max={2}>
                {avatarList.map((avatar) => {
                    const name = avatar.user.name ?? '';
                    return (
                        <UserAccountAvatar
                            key={avatar.user.id}
                            label={name}
                            tooltipTitle={name}
                        />
                    );
                })}
            </AvatarGroup>
        </div>
    );
};

export default AccessList;
