import { type ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Alert, DialogContent, Drawer, Slide, Snackbar } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';

import {
    Button,
    DialogActions,
    DialogTitle,
    PlusIcon,
} from '@xeris/components';
import { SelectInput, TextInput } from '@xeris/components/forms';
import { AutocompleteMultipleInputChips } from '@xeris/components/forms/AutocompleteMultipleInputChips/AutocompleteMultipleInputChips';
import { organizationsApi } from '@xeris/pages/admin/api';

import {
    getOrganizationTypeList,
    type OrganizationFormData,
    organizationSchema,
} from '../utilities';

const AddOrganization = (): ReactElement => {
    const { t } = useTranslation('administration');
    const typeList = getOrganizationTypeList(t);

    const [isOpen, setIsOpen] = useState(false);
    const [isSaveErrorOpen, setIsSaveErrorOpen] = useState(false);
    const [isCreateOrganizationSuccess, setIsCreateOrganizationSuccess] =
        useState(false);

    const [addOrganizationMutation, addOrganizationResult] =
        organizationsApi.useAddOrganizationMutation();

    const { handleSubmit, control, reset } = useForm<OrganizationFormData>({
        resolver: yupResolver(organizationSchema),
        defaultValues: {
            id: '',
            name: '',
            type: 'PRODUCER',
            domains: [],
            vatNumber: '',
        },
    });

    const handleSubmitOrganization = async (
        data: OrganizationFormData
    ): Promise<void> => {
        try {
            await addOrganizationMutation({
                ...data,
                domains: data.domains ?? null,
                vatNumber: data.vatNumber ?? null,
            }).unwrap();

            reset();
            setIsOpen(false);
            setIsCreateOrganizationSuccess(true);
        } catch (error) {
            setIsSaveErrorOpen(true);
        }
    };

    const handleClose = (): void => {
        setIsOpen(false);
    };

    return (
        <>
            <Button
                startIcon={<PlusIcon color="white" />}
                onClick={(): void => setIsOpen(true)}
            >
                {t(`organization.newOrganization`)}
            </Button>

            <Drawer
                open={isOpen}
                onClose={handleClose}
                PaperProps={{
                    'component': 'form',
                    'aria-label': t(
                        `organization.addNewOrganization.addNewOrganization`
                    ),
                }}
                onSubmit={handleSubmit((data) => {
                    handleSubmitOrganization(data);
                })}
            >
                <DialogTitle>
                    {t(`organization.addNewOrganization.addNewOrganization`)}
                </DialogTitle>
                <DialogContent dividers sx={{ maxWidth: '500px' }}>
                    <TextInput
                        label={t('organization.id')}
                        fieldName={'id'}
                        control={control}
                        required
                        showError
                        autoFocus
                        placeholder={t('organization.id')}
                    />
                    <TextInput
                        label={t('organization.name')}
                        fieldName={'name'}
                        control={control}
                        required
                        showError
                        placeholder={t('organization.name')}
                    />
                    <SelectInput
                        fieldName={'type'}
                        label={t(
                            'organization.addNewOrganization.organizationType'
                        )}
                        options={typeList}
                        control={control}
                    />
                    <AutocompleteMultipleInputChips
                        fieldName={'domains'}
                        label={t(
                            'organization.addNewOrganization.organizationDomain'
                        )}
                        control={control}
                        showError
                        size={'small'}
                        options={[]}
                        placeholder={t(
                            'organization.addNewOrganization.organizationDomainPlaceholder'
                        )}
                    />
                    <TextInput
                        label={t(
                            'organization.addNewOrganization.vatNumberOptional'
                        )}
                        fieldName={'vatNumber'}
                        control={control}
                        required
                        showError
                        placeholder={t(
                            'organization.addNewOrganization.vatNumber'
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    {handleClose && (
                        <Button
                            variant="textNeutral"
                            onClick={(): void => handleClose()}
                        >
                            {t('common.cancel')}
                        </Button>
                    )}
                    <Button
                        type={'submit'}
                        disabled={addOrganizationResult.isLoading}
                    >
                        {t('brand.common.create')}
                    </Button>
                </DialogActions>
            </Drawer>

            <Snackbar
                open={isSaveErrorOpen && !!addOrganizationResult.error}
                autoHideDuration={4000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                TransitionComponent={Slide}
            >
                <Alert
                    severity="error"
                    elevation={2}
                    onClose={() => setIsSaveErrorOpen(false)}
                >
                    {t('organization.somethingWentWrong')}
                </Alert>
            </Snackbar>
            <Snackbar
                open={isCreateOrganizationSuccess}
                autoHideDuration={2500}
                onClose={() => setIsCreateOrganizationSuccess(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                TransitionComponent={Slide}
            >
                <Alert severity="success" elevation={2}>
                    {t('organization.addNewOrganization.success')}
                </Alert>
            </Snackbar>
        </>
    );
};

export default AddOrganization;
