import { type ReactElement, type ReactNode } from 'react';

import { Drawer } from '@mui/material';

import { type AlignType } from './SlidingDrawer';

type FullscreenDrawerProps = {
    children: ReactNode;
    align: AlignType;
    show: boolean;
    paperProps?: Record<string, unknown>;
    handleClose: () => void;
};

const FullscreenDrawer = ({
    children,
    align,
    show,
    paperProps,
    handleClose,
}: FullscreenDrawerProps): ReactElement => {
    return (
        <Drawer
            anchor={align}
            open={show}
            onClose={(): void => handleClose()}
            PaperProps={paperProps}
        >
            {children}
        </Drawer>
    );
};

export default FullscreenDrawer;
