import { type ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Alert, DialogContent, Drawer, Slide, Snackbar } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
    Button,
    DialogActions,
    DialogTitle,
    PlusIcon,
} from '@xeris/components';
import { TextInput } from '@xeris/components/forms';
import { brandApi } from '@xeris/pages/admin/api';
import { useActiveOrganizationId } from '@xeris/pages/admin/hooks';

const newBrandSchema = yup
    .object({
        id: yup.string().required('required'),
        name: yup.string().required('required'),
    })
    .required();

export type NewBrandFormData = yup.InferType<typeof newBrandSchema>;

const AddBrand = (): ReactElement | null => {
    const { t } = useTranslation('administration');

    const [isOpen, setIsOpen] = useState(false);
    const [isSaveErrorOpen, setIsSaveErrorOpen] = useState(false);
    const [isSubmitBrandSuccess, setIsSubmitBrandSuccess] = useState(false);

    const activeOrganizationId = useActiveOrganizationId();

    const [addBrandMutation, addBrandResult] = brandApi.useAddBrandMutation();

    const { handleSubmit, control, reset } = useForm<NewBrandFormData>({
        resolver: yupResolver(newBrandSchema),
        defaultValues: {
            id: '',
            name: '',
        },
    });

    if (!activeOrganizationId) {
        return null;
    }

    const handleSubmitBrand = async (
        newBrandData: NewBrandFormData
    ): Promise<void> => {
        try {
            await addBrandMutation({
                brandOwner: activeOrganizationId,
                ...newBrandData,
            }).unwrap();
            reset();
            setIsOpen(false);
            setIsSubmitBrandSuccess(true);
        } catch (error) {
            setIsSaveErrorOpen(true);
        }
    };

    const handleClose = (): void => {
        setIsOpen(false);
    };

    return (
        <>
            <Button
                startIcon={<PlusIcon color="white" />}
                onClick={(): void => setIsOpen(true)}
            >
                {t(`brand.addBrand.addNewBrand`)}
            </Button>

            <Drawer
                open={isOpen}
                onClose={handleClose}
                PaperProps={{
                    'component': 'form',
                    'aria-label': t(`brand.addBrand.addNewBrandLong`),
                }}
                onSubmit={handleSubmit(handleSubmitBrand)}
            >
                <DialogTitle>{t(`brand.addBrand.addNewBrandLong`)}</DialogTitle>
                <DialogContent dividers sx={{ maxWidth: '500px' }}>
                    <TextInput
                        label={t('brand.addBrand.id')}
                        fieldName={'id'}
                        control={control}
                        required
                        showError
                        autoFocus
                        placeholder={t('brand.addBrand.idPlaceholder')}
                    />
                    <TextInput
                        label={t('brand.addBrand.name')}
                        fieldName={'name'}
                        control={control}
                        required
                        showError
                        placeholder={t('brand.addBrand.namePlaceholder')}
                    />
                </DialogContent>
                <DialogActions>
                    {handleClose && (
                        <Button
                            variant="textNeutral"
                            onClick={(): void => {
                                reset();
                                handleClose();
                            }}
                        >
                            {t('common.cancel')}
                        </Button>
                    )}
                    <Button type={'submit'} disabled={addBrandResult.isLoading}>
                        {t('brand.common.create')}
                    </Button>
                </DialogActions>
            </Drawer>

            <Snackbar
                open={isSaveErrorOpen && !!addBrandResult.error}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                TransitionComponent={Slide}
            >
                <Alert
                    severity="error"
                    elevation={2}
                    onClose={() => setIsSaveErrorOpen(false)}
                >
                    {t('brand.addBrand.errorOnAdd')}
                </Alert>
            </Snackbar>
            <Snackbar
                open={isSubmitBrandSuccess}
                autoHideDuration={4000}
                onClose={() => setIsSubmitBrandSuccess(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                TransitionComponent={Slide}
            >
                <Alert severity="success" elevation={2}>
                    {t('brand.addBrand.addBrandSuccess')}
                </Alert>
            </Snackbar>
        </>
    );
};

export default AddBrand;
