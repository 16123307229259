import type { ReactElement } from 'react';

import { type MenuElementType, NavigationRail } from '@xeris/components';
import { useWhoami } from '@xeris/hooks';
import { useAdminMenu } from '@xeris/pages/admin/useAdminMenu';
import { useDatasetMenu } from '@xeris/pages/dataset/useDatasetMenu';
import { useEcoSearchMenu } from '@xeris/pages/ecoSearch/useEcoSearchMenu';
import { useExportMenu } from '@xeris/pages/exportJob/useExportMenu';
import { useProductsMenu } from '@xeris/pages/product/ProductSearch/hooks/useProductsMenu';

const Menu = (): ReactElement => {
    const { isSuperAdmin, isAdmin } = useWhoami();

    const adminMenu = useAdminMenu();

    const menu: MenuElementType[][] = [
        useProductsMenu(),
        useEcoSearchMenu(),
        isSuperAdmin || isAdmin ? adminMenu : [],
        useExportMenu(),
        useDatasetMenu(),
    ];

    const menuElements = menu.flat().map((element) => ({
        ...element,
        topLevel: true,
    }));

    return <NavigationRail menuElements={menuElements} />;
};

export default Menu;
