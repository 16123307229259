import { type ReactElement } from 'react';
import { type Control, type Path, useWatch } from 'react-hook-form';

import { Box } from '@mui/material';

import { AutocompleteMultipleInput } from '@xeris/components/forms';

import { type FormData } from '../connectionSchema';

type TradingPartnerProps = {
    fieldName: Path<FormData>;
    label: string;
    control: Control<FormData>;
    marketList: {
        id: string;
        label: string;
    }[];
};

function AccessToMarketsField({
    fieldName,
    label,
    control,
    marketList,
}: TradingPartnerProps): ReactElement | null {
    const accessRuleType = useWatch({ control, name: 'accessRuleType' });

    return (
        <Box display={accessRuleType === 'markets' ? 'initial' : 'none'}>
            <AutocompleteMultipleInput
                showError
                fieldName={fieldName}
                control={control}
                label={label}
                options={marketList}
                disableCloseOnSelect
            />
        </Box>
    );
}

export default AccessToMarketsField;
