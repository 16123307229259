import { type ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Alert,
    AlertTitle,
    Box,
    Button,
    DialogActions,
    TextField,
} from '@mui/material';

import { type HandleAddToDatasetData } from '@xeris/pages/product/ProductDataSelector/types';
import { type NewDataset } from '@xeris/pages/product/types';

import SelectDataset from '../SelectDataset/SelectDataset';

type CancelButtonProps = {
    handleClose?: () => void;
    isInlineDialog?: boolean;
};

const CancelButton = ({
    handleClose,
    isInlineDialog,
}: CancelButtonProps): ReactElement | null => {
    const { t } = useTranslation('product');
    if (isInlineDialog) {
        return null;
    }
    return (
        <Button
            variant={'text'}
            color={'secondary'}
            onClick={handleClose}
            size="large"
            sx={{ height: '52px' }}
        >
            {t('common.cancel')}
        </Button>
    );
};

type AddToDatasetButtonProps = {
    handleAddToDataset: () => void;
    activeDatasetName: string | undefined;
    newDatasetName: string;
    isEditing?: boolean;
};

const AddToDatasetButton = ({
    handleAddToDataset,
    activeDatasetName,
    newDatasetName,
    isEditing,
}: AddToDatasetButtonProps): ReactElement => {
    const { t } = useTranslation('product');

    const disabled = !activeDatasetName && !newDatasetName;

    return (
        <Button
            onClick={handleAddToDataset}
            variant="contained"
            color="primary"
            disabled={disabled}
            size="large"
            sx={{ height: '52px' }}
        >
            {isEditing
                ? t('dataSelector.save')
                : t('dataSelector.addToDataset')}
        </Button>
    );
};

type FooterProps = {
    isInlineDialog: boolean;
    handleClose?: () => void;
    handleAddToDataset: (data: HandleAddToDatasetData) => void;
    newDataset: NewDataset;
    activeDatasetName: string | undefined;
    handleSetNewDataset: (dataset: NewDataset) => void;
    setActiveDatasetId: (datasetId: string | null) => void;
    activeDatasetId: string | null;
    isEditing?: boolean;
    datasetSelectorDisabled?: boolean;
    configurationSetName?: string;
    isError: boolean;
};

export const Footer = ({
    isInlineDialog = false,
    handleClose,
    handleAddToDataset,
    newDataset,
    activeDatasetName,
    handleSetNewDataset,
    setActiveDatasetId,
    activeDatasetId,
    isEditing,
    datasetSelectorDisabled,
    configurationSetName,
    isError,
}: FooterProps): ReactElement => {
    const { t } = useTranslation('product');
    const configurationNameInputRef = useRef<HTMLInputElement>();

    const [newConfigurationSetName, setNewConfigurationSetName] = useState<
        string | undefined
    >();

    useEffect(() => {
        setNewConfigurationSetName(configurationSetName);
    }, [configurationSetName]);

    const addToDatasetClicked = (): void => {
        if (isInlineDialog && !newConfigurationSetName) {
            configurationNameInputRef.current?.focus();

            return;
        }

        handleAddToDataset({
            configurationSetName: newConfigurationSetName,
        });
    };

    return (
        <DialogActions sx={{ marginBlock: 1, gap: 2 }}>
            {isError && (
                <Box sx={{ flexGrow: 1 }}>
                    <Alert severity={'error'} sx={{ maxWidth: '250px' }}>
                        <AlertTitle>{t('errors.failedToSave')}</AlertTitle>
                    </Alert>
                </Box>
            )}
            <CancelButton
                handleClose={handleClose}
                isInlineDialog={isInlineDialog}
            />
            {isInlineDialog && (
                <TextField
                    inputRef={configurationNameInputRef}
                    InputLabelProps={{ shrink: true }}
                    label={t('dataSelector.configurationSetName')}
                    value={newConfigurationSetName ?? ''}
                    onChange={(event) =>
                        setNewConfigurationSetName(event.target.value)
                    }
                    error={isInlineDialog && !newConfigurationSetName}
                />
            )}
            <SelectDataset
                handleSetShowAddDataset={(showForm: boolean): void => {
                    handleSetNewDataset({ ...newDataset, showForm });
                }}
                name={newDataset.name}
                showAddDataset={newDataset.showForm}
                setActiveDatasetId={setActiveDatasetId}
                activeDatasetId={activeDatasetId}
                disabled={datasetSelectorDisabled}
            />
            <AddToDatasetButton
                activeDatasetName={activeDatasetName}
                newDatasetName={newDataset.name}
                handleAddToDataset={addToDatasetClicked}
                isEditing={isEditing}
            />
        </DialogActions>
    );
};
