import { type ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Alert, DialogContent, Drawer, Slide, Snackbar } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';

import { Button, DialogActions, DialogTitle } from '@xeris/components';
import {
    AutocompleteMultipleInputChips,
    SelectInput,
    TextInput,
} from '@xeris/components/forms';
import { organizationsApi } from '@xeris/pages/admin/api';

import { type OrganizationTypes } from '../types';
import {
    getOrganizationTypeList,
    type OrganizationFormData,
    organizationSchema,
} from '../utilities';

type EditOrganizationProps = {
    isOpen: boolean;
    organizationData?: OrganizationTypes.OrganizationType;
    handleClose: () => void;
};

const EditOrganization = ({
    isOpen,
    organizationData,
    handleClose,
}: EditOrganizationProps): ReactElement | null => {
    const { t } = useTranslation('administration');
    const typeList = getOrganizationTypeList(t);

    const [isSaveErrorOpen, setIsSaveErrorOpen] = useState(false);
    const [isEditOrganizationSuccess, setIsEditOrganizationSuccess] =
        useState(false);

    const [editOrganizationMutation, editOrganizationResult] =
        organizationsApi.useEditOrganizationMutation();

    const { handleSubmit, control, reset } = useForm<OrganizationFormData>({
        resolver: yupResolver(organizationSchema),
    });

    const handleSubmitOrganization = async (
        data: OrganizationFormData
    ): Promise<void> => {
        try {
            await editOrganizationMutation({
                ...data,
                domains: data.domains ?? null,
                vatNumber: data.vatNumber ?? null,
            }).unwrap();

            reset(data);
            setIsEditOrganizationSuccess(true);
            handleClose();
        } catch (error) {
            setIsSaveErrorOpen(true);
        }
    };

    useEffect(() => {
        reset({
            id: organizationData?.id ?? '',
            name: organizationData?.name ?? '',
            type: organizationData?.type ?? 'PRODUCER',
            domains: organizationData?.domains ?? [],
            vatNumber: organizationData?.vatNumber ?? '',
        });
    }, [reset, organizationData]);

    return (
        <>
            <Drawer
                open={isOpen}
                onClose={handleClose}
                PaperProps={{
                    'component': 'form',
                    'aria-label': t(
                        `organization.editOrganization.editOrganization`
                    ),
                }}
                onSubmit={handleSubmit(handleSubmitOrganization)}
            >
                <>
                    <DialogTitle>
                        {t(`organization.editOrganization.editOrganization`)}
                    </DialogTitle>
                    <DialogContent dividers sx={{ maxWidth: '500px' }}>
                        <TextInput
                            label={t('organization.id')}
                            fieldName={'id'}
                            control={control}
                            disabled
                            required
                            showError
                            placeholder={t('organization.id')}
                        />
                        <TextInput
                            label={t('organization.name')}
                            fieldName={'name'}
                            control={control}
                            required
                            showError
                            autoFocus
                            placeholder={t('organization.name')}
                        />
                        <SelectInput
                            fieldName={'type'}
                            label={t(
                                'organization.editOrganization.organizationType'
                            )}
                            options={typeList}
                            control={control}
                        />
                        <AutocompleteMultipleInputChips
                            fieldName={'domains'}
                            label={t(
                                'organization.addNewOrganization.organizationDomain'
                            )}
                            control={control}
                            showError
                            size={'small'}
                            options={organizationData?.domains}
                            placeholder={t(
                                'organization.addNewOrganization.organizationDomainPlaceholder'
                            )}
                        />
                        <TextInput
                            label={t(
                                'organization.editOrganization.vatNumberOptional'
                            )}
                            fieldName={'vatNumber'}
                            control={control}
                            required
                            showError
                            placeholder={t(
                                'organization.editOrganization.vatNumber'
                            )}
                        />
                    </DialogContent>
                    <DialogActions>
                        {handleClose && (
                            <Button
                                variant="textNeutral"
                                onClick={(): void => handleClose()}
                            >
                                {t('common.cancel')}
                            </Button>
                        )}
                        <Button
                            type={'submit'}
                            disabled={editOrganizationResult.isLoading}
                        >
                            {t('brand.common.save')}
                        </Button>
                    </DialogActions>
                </>
            </Drawer>

            <Snackbar
                open={isSaveErrorOpen && !!editOrganizationResult.error}
                autoHideDuration={4000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                TransitionComponent={Slide}
            >
                <Alert
                    severity="error"
                    elevation={2}
                    onClose={() => setIsSaveErrorOpen(false)}
                >
                    {t('organization.somethingWentWrong')}
                </Alert>
            </Snackbar>
            <Snackbar
                open={isEditOrganizationSuccess}
                autoHideDuration={2500}
                onClose={() => setIsEditOrganizationSuccess(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                TransitionComponent={Slide}
            >
                <Alert severity="success" elevation={2}>
                    {t('organization.editOrganization.success')}
                </Alert>
            </Snackbar>
        </>
    );
};

export default EditOrganization;
