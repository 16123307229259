import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { type TFunction } from 'i18next';

import { type TabProviderTabType, TabsProvider } from '@xeris/components';
import { useActiveOrganization } from '@xeris/pages/admin/hooks';

import CountIcon from '../../common/CountIcon/CountIcon';
import {
    type ConnectionCounters,
    type ConnectionsTabVariantType,
} from '../../types/connectionsTypes';

import styles from './ConnectionTabs.module.scss';

const getTabList = (
    t: TFunction<'administration'>,
    counters: ConnectionCounters,
    selectedTab: ConnectionsTabVariantType
): TabProviderTabType<ConnectionsTabVariantType>[] => [
    {
        id: 'channels',
        Label: (
            <div className={styles.tabLabel}>
                {t(`connections.channels`)}{' '}
                <CountIcon
                    count={counters.channels}
                    isActive={selectedTab === 'channels'}
                />
            </div>
        ),
        Component: <></>,
    },
    {
        id: 'feeds',
        Label: (
            <div className={styles.tabLabel}>
                {t(`connections.feeds`)}{' '}
                <CountIcon
                    count={counters.feeds}
                    isActive={selectedTab === 'feeds'}
                />
            </div>
        ),
        Component: <></>,
    },
];

type ConnectionTabsProps = {
    counters: ConnectionCounters;
    selectedConnectionType: ConnectionsTabVariantType;
    setSelectedConnectionType: (type: ConnectionsTabVariantType) => void;
};

const ConnectionTabs = ({
    counters,
    selectedConnectionType,
    setSelectedConnectionType,
}: ConnectionTabsProps): ReactElement | null => {
    const { t } = useTranslation('administration');

    const organization = useActiveOrganization();

    if (organization?.type !== 'PRODUCER_CONSUMER') return null;

    return (
        <TabsProvider
            activeTabId={selectedConnectionType}
            tabList={getTabList(t, counters, selectedConnectionType)}
            handleChangeTabId={(newActiveTabId): void =>
                setSelectedConnectionType(newActiveTabId)
            }
        />
    );
};

export default ConnectionTabs;
