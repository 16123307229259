import { type ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import { Image } from '@xeris/components';
import { type BasicOption } from '@xeris/pages/product/types';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';

import { Price } from '../../../Common';
import { selectPreview, variantGeneratorSelectors } from '../../../reducer';

import { OptionCardButtons } from './OptionCardButtons';

import styles from './OptionCard.module.scss';

type OptionCardProps = {
    featureId: string;
    option: BasicOption;
};

const OptionCard = ({
    option,
    featureId,
}: OptionCardProps): ReactElement | null => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('product');

    const handleSelectPreview = useCallback((): void => {
        dispatch(
            selectPreview({
                optionId: option.id,
                featureId: featureId,
            })
        );
    }, [dispatch, featureId, option.id]);

    const classNames = [styles.optionCard];

    const { isPreviewable, isSelectable, isVisible } = useAppSelector((state) =>
        variantGeneratorSelectors.optionStatus(state, featureId, option.id)
    );

    if (!isVisible) {
        return null;
    }

    return (
        <Box
            className={classNames.join(' ')}
            onClick={(): void => {
                if (isPreviewable) {
                    handleSelectPreview();
                }
            }}
            sx={{
                'padding': 2,
                '&:hover ': { '.preview': { opacity: 1 } },
                '&:focus-within': { '.preview': { opacity: 1 } },
                'cursor': isPreviewable ? 'pointer' : 'initial',
            }}
        >
            <Image
                alt={t('variantGenerator.featureOptionPreview')}
                src={option.image ?? undefined}
            />
            <div>
                <span className={styles.name} title={option.name}>
                    {option.name}
                </span>
                <div className={styles.details}>
                    <div className={styles.description}>
                        {option.description}
                    </div>

                    <div className={styles.actions}>
                        <Price price={option.price} />

                        <OptionCardButtons
                            featureId={featureId}
                            option={option}
                            handleSelectPreview={handleSelectPreview}
                            isPreviewable={isPreviewable}
                            isSelectable={isSelectable}
                        />
                    </div>
                </div>
            </div>
        </Box>
    );
};
export default OptionCard;
