import { type ConnectionTableEntry } from '../../../components/ConnectionList/ConnectionList';
import { type ConnectionsTabVariantType } from '../../../types/connectionsTypes';
import { type FormData } from '../connectionSchema';
import { type FormInputData } from '../useGetFormData';

import { convertRulesToFormData } from './convertRulesToFormData';

export const getDefaultValues = (
    connectionType: ConnectionsTabVariantType,
    connection: ConnectionTableEntry | undefined,
    data: FormInputData
): FormData => {
    return {
        tradingPartner:
            connectionType === 'channels'
                ? connection?.consumer.id ?? ''
                : connection?.producer.id ?? '',
        configs: (
            data.exportConfigList.filter((config) =>
                connection?.exports.find((c) => c.id === config.id)
            ) ?? []
        ).map((config) => ({ ...config, label: config.name })),
        ...convertRulesToFormData(data),
    };
};
