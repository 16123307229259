import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Alert, Box, Skeleton, Slide, Snackbar, Toolbar } from '@mui/material';

import { type TFunction } from 'i18next';

import {
    DataGrid,
    ErrorPage,
    SearchBar,
    type TypeSafeColDef,
} from '@xeris/components';
import { useWhoami } from '@xeris/hooks';
import { userApi, userVerificationApi } from '@xeris/pages/admin/api';
import { useActiveOrganizationId } from '@xeris/pages/admin/hooks';
import type { ApiSecurityLevel } from '@xeris/types';

import { InviteUserButton } from '../components/InviteUserButton';
import { ResendInviteForm } from '../forms/ResendInviteForm';

import UserActionsMenu from './UserActionsMenu/UserActionsMenu';
import RegistrationStatusChips from './RegistrationStatusChips';

type User = {
    id: string;
    name: string | null;
    username: string;
    registrationStatus: string;
    securityLevel: ApiSecurityLevel | null;
    language: string;
};

const getColumns = (
    t: TFunction<'administration'>,
    userId: string,
    isSuperAdmin: boolean,
    resetUserPassword: (username: string) => void,
    setResendInviteUser: (user: User) => void
): TypeSafeColDef<User>[] => [
    {
        field: 'name',
        headerName: t('users.name'),
        valueGetter: (params) => params.row.name?.trim() || params.row.username,
        flex: 2,
    },
    {
        field: 'username',
        headerName: t('users.email'),
        flex: 1,
    },
    {
        field: 'registrationStatus',
        headerName: t('users.status'),
        width: 120,
        renderCell: (params): ReactElement => {
            return (
                <RegistrationStatusChips registrationStatus={params.value} />
            );
        },
    },
    {
        field: 'securityLevel',
        headerName: t('users.role'),
        width: 70,
        renderCell: (params): ReactElement => {
            return <Role securityLevel={params.value} />;
        },
    },
    {
        field: 'id',
        headerName: ' ',
        align: 'right',
        width: 80,
        sortable: false,
        renderCell: (params): ReactElement => {
            return (
                <UserActionsMenu
                    user={params.row}
                    canImpersonate={isSuperAdmin && userId !== params.row.id}
                    resetUserPassword={resetUserPassword}
                    setResendInviteUser={setResendInviteUser}
                />
            );
        },
    },
];
type RoleProps = {
    securityLevel: ApiSecurityLevel;
};

const Role = ({ securityLevel }: RoleProps): ReactElement => {
    const { t } = useTranslation('administration');
    return <span>{t(`users.securityLevel.${securityLevel}`)}</span>;
};

const UserList = (): ReactElement => {
    const { t } = useTranslation(['administration', 'common']);

    const [searchTerm, setSearchTerm] = useState('');

    const [resetUserPassword, resetUserPasswordResult] =
        userVerificationApi.useResetUserPasswordMutation();

    const activeOrganizationId = useActiveOrganizationId();
    const { userId, isSuperAdmin } = useWhoami();

    const [resendInviteUser, setResendInviteUser] = useState<User | null>(null);

    const { data, isLoading, isError, refetch } = userApi.useGetUsersQuery({
        organizationId: activeOrganizationId ?? '',
    });

    if (isLoading) {
        return <Skeleton height={300} />;
    }

    if (isError) {
        return (
            <ErrorPage
                title={t('apiErrors.anErrorOccurred', { ns: 'common' })}
                onClick={refetch}
                actionText={t('apiErrors.tryAgain', { ns: 'common' })}
            />
        );
    }

    if (!data) {
        return <ErrorPage title={t('apiErrors.notFound', { ns: 'common' })} />;
    }

    const userList: User[] = data.users.filter((user) =>
        searchTerm
            .toLowerCase()
            .split(' ')
            .every((term) =>
                [user.name, user.username]
                    .join(' ')
                    .toLowerCase()
                    .includes(term)
            )
    );

    return (
        <>
            <Toolbar disableGutters>
                <SearchBar
                    filter={searchTerm}
                    onChange={(value) => setSearchTerm(value)}
                    inputLabel={t('search', { ns: 'common' })}
                />
                <Box sx={{ flexGrow: 1 }} />
                <InviteUserButton />
            </Toolbar>
            <DataGrid
                rows={userList}
                columns={getColumns(
                    t,
                    userId,
                    isSuperAdmin,
                    resetUserPassword,
                    setResendInviteUser
                )}
                pageSizeOptions={[25, 50, 75, 100]}
                autoHeight
                disableRowSelectionOnClick
            />
            <ResendInviteForm
                isOpen={!!resendInviteUser}
                handleClose={() => setResendInviteUser(null)}
                user={resendInviteUser}
            />
            <Snackbar
                open={resetUserPasswordResult.isSuccess}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                TransitionComponent={Slide}
                onClose={() => resetUserPasswordResult.reset()}
            >
                <Alert elevation={2}>{t('users.resetPasswordSent')}</Alert>
            </Snackbar>
            <Snackbar
                open={resetUserPasswordResult.isError}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                TransitionComponent={Slide}
                onClose={() => resetUserPasswordResult.reset()}
            >
                <Alert severity={'error'} elevation={2}>
                    {resetUserPasswordResult.error &&
                        'data' in resetUserPasswordResult.error &&
                        typeof resetUserPasswordResult.error.data ===
                            'string' &&
                        resetUserPasswordResult.error.data}
                </Alert>
            </Snackbar>
        </>
    );
};

export default UserList;
