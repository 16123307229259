import * as yup from 'yup';

import { type AccessType, accessTypes } from './types';

const objectArray = yup
    .object({
        id: yup.string().required('required'),
        name: yup.string().required('required'),
        label: yup.string().required('required'),
    })
    .required('required');

export const connectionSchema = yup
    .object({
        tradingPartner: yup.string().required('required'),
        configs: yup.array().of(objectArray).required('required'),
        accessRuleType: yup
            .mixed<AccessType>()
            .required('required')
            .oneOf(accessTypes),
        accessToBrands: yup.array().of(objectArray).required('required'),
        accessToMarkets: yup.array().of(objectArray).required('required'),
    })
    .required();

export type FormData = yup.InferType<typeof connectionSchema>;
