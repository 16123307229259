import { memo, type ReactElement } from 'react';

import { Typography } from '@xeris/components';
import { type BasicFeature } from '@xeris/pages/product/types';
import { useAppSelector } from '@xeris/reducers';

import { variantGeneratorSelectors } from '../../../reducer';
import { numberOrDash } from '../../../utilities/numberOrDash';

import FeatureOptionList from './FeatureOptionList';

import styles from './BasicPanel.module.scss';

type FeatureHeaderProps = {
    id: string;
    name: string;
    description?: string;
};

type BasicPanelProps = {
    feature: BasicFeature;
};

const FeatureHeader = ({
    id,
    name,
    description,
}: FeatureHeaderProps): ReactElement | null => {
    const { available, selected } = useAppSelector((state) =>
        variantGeneratorSelectors.selectedOptionCount(state, id)
    );

    return (
        <div className={styles.featureHeader} id={id}>
            <div>
                <Typography variant={'h3'} gutterBottom>
                    {name}
                </Typography>
                <Typography variant={'body2'}>{description}</Typography>
            </div>
            <div className={styles.optionCounter}>
                <span className={styles.selected}>
                    {numberOrDash(selected)}
                </span>
                <span className={styles.available}>{`/ ${available}`}</span>
            </div>
        </div>
    );
};

const BasicPanel = ({ feature }: BasicPanelProps): ReactElement | null => {
    const { isVisible } = useAppSelector((state) =>
        variantGeneratorSelectors.featureStatus(state, feature.id)
    );

    if (!isVisible) {
        return null;
    }

    return (
        <div className={styles.basicPanel}>
            <FeatureHeader id={feature.id} name={feature.name} />
            <FeatureOptionList feature={feature} />
        </div>
    );
};

export default memo(BasicPanel);
