import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { PlusIcon } from '@xeris/components';

import { CreateExportButton } from '../components/CreateExportButton';

import ExportJobListData from './components/ExportJobListData';

const Header = (): ReactElement => {
    const { t } = useTranslation('exports');

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant={'h1'}>{t('common.exports')}</Typography>
            <CreateExportButton startIcon={<PlusIcon fontSize="small" />} />
        </Box>
    );
};

const ExportJobList = (): ReactElement => {
    return (
        <div className={'main-content-max-width'}>
            <Header />
            <ExportJobListData />
        </div>
    );
};

export default ExportJobList;
