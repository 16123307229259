import { type ReactElement } from 'react';

import { Button, CloseIcon, PlusIcon } from '@xeris/components';
import { LanguageSelector } from '@xeris/containers';

import { type EcoSearchTypes } from '../types';

import styles from './ProductBrowser.module.scss';

type FilterSectionProps = {
    handleSetIsDrawerOpen: (newValue: boolean) => void;
    filters: Record<string, EcoSearchTypes.FilterType>;
    handleRemoveFilter: (filterId: string) => void;
};

export const FilterSection = ({
    handleSetIsDrawerOpen,
    filters,
    handleRemoveFilter,
}: FilterSectionProps): ReactElement => {
    const filterComp = [styles.filterComp, styles.withProducts];

    return (
        <section className={filterComp.join(' ')}>
            <div className={styles.filterOptions}>
                <div className={styles.filterApplied}>
                    <Button
                        variant="outlinedNeutral"
                        onClick={(): void => handleSetIsDrawerOpen(true)}
                    >
                        <PlusIcon fontSize="small" />
                        Add filters
                    </Button>

                    <div className={styles.filterApplied}>
                        {Object.values(filters)
                            .filter((it) => it.isActive)
                            .map((filter) => (
                                <Button
                                    key={filter.id}
                                    variant="outlinedNeutral"
                                    onClick={(): void =>
                                        handleRemoveFilter(filter.id)
                                    }
                                >
                                    <CloseIcon fontSize="small" />
                                    {filter.name}
                                </Button>
                            ))}
                    </div>
                </div>
                <div className={styles.productControls}>
                    <LanguageSelector />
                </div>
            </div>
        </section>
    );
};
