import { type ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Toolbar, Typography } from '@mui/material';

import {
    CellLink,
    DataGrid,
    SearchBar,
    type TypeSafeColDef,
} from '@xeris/components';
import { useLocale } from '@xeris/hooks';
import { brandApi } from '@xeris/pages/admin/api';
import { useActiveOrganization } from '@xeris/pages/admin/hooks';

import AddBrand from '../AddBrand/AddBrand';
import EditBrand from '../EditBrand/EditBrand';

import BrandActionsMenu from './BrandActionsMenu/BrandActionsMenu';
import { DeleteBrandDialog } from './BrandActionsMenu/DeleteBrandDialog';

type RowData = {
    id: string;
    name: string;
    masterProductsCount: number;
    brand: {
        id: string;
        name: string;
    };
};

type Brand = {
    id: string;
    name: string;
};

const BrandsList = (): ReactElement | null => {
    const { t } = useTranslation('administration');
    const { t: tCommon } = useTranslation('common');
    const locale = useLocale();

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedBrand, setSelectedBrand] = useState<Brand | undefined>(
        undefined
    );
    const [selectedDeleteBrand, setSelectedDeleteBrand] = useState<
        Brand | undefined
    >(undefined);

    const activeOrganization = useActiveOrganization();

    const { data } = brandApi.useGetAdminBrandsForOrganizationQuery({
        organizationId: activeOrganization?.id ?? '',
    });

    const brandList =
        data?.organization?.brands?.filter((brand) =>
            searchTerm
                .toLowerCase()
                .split(' ')
                .every(
                    (term) =>
                        brand.name.toLowerCase().includes(term) ||
                        brand.id.toLowerCase().includes(term)
                )
        ) ?? [];

    const rows: RowData[] = brandList.map((brand) => ({
        id: brand.id,
        name: brand.name,
        masterProductsCount: brand.productCounts.masterProducts ?? 0,
        brand: brand,
    }));

    const columns: TypeSafeColDef<RowData>[] = [
        {
            field: 'id',
            headerName: t('brand.common.brandID'),
            width: 200,
            renderCell: ({ row }): ReactElement => (
                <CellLink to={`/Admin/Brands/${row.id}`} noWrap>
                    {row.id}
                </CellLink>
            ),
        },
        {
            field: 'name',
            headerName: t('brand.common.name'),
            flex: 6,
            renderCell: ({ row }): ReactElement => (
                <CellLink
                    to={`/Admin/Brands/${row.id}`}
                    noWrap
                    fontWeight={'medium'}
                >
                    {row.name}
                </CellLink>
            ),
        },
        {
            field: 'masterProductsCount',
            headerName: t('brand.common.products'),
            width: 100,
            headerAlign: 'right',
            align: 'right',
            valueFormatter: ({ value }) =>
                Intl.NumberFormat(locale).format(value),
        },
        {
            field: 'actions',
            headerName: '',
            renderHeader: (): ReactElement => <span />,
            renderCell: ({ row }): ReactElement => (
                <BrandActionsMenu
                    brand={row.brand}
                    setSelectedBrand={setSelectedBrand}
                    setSelectedDeleteBrand={setSelectedDeleteBrand}
                />
            ),
            width: 120,
            headerAlign: 'right',
            align: 'right',
            sortable: false,
        },
    ];

    return (
        <>
            <Typography variant={'h1'} gutterBottom>
                {t('brand.brands')}
            </Typography>
            <Toolbar disableGutters>
                <SearchBar
                    filter={searchTerm}
                    onChange={(value) => setSearchTerm(value)}
                    inputLabel={tCommon('search')}
                />
                <Box sx={{ flexGrow: 1 }} />
                <AddBrand />
            </Toolbar>
            {brandList.length === 0 && (
                <div>{t('brand.organizationHasNoBrands')}</div>
            )}
            {brandList.length > 0 && (
                <DataGrid
                    rows={rows}
                    columns={columns}
                    autoHeight
                    pagination={undefined}
                    hideFooter
                    disableRowSelectionOnClick
                />
            )}

            <EditBrand
                isOpen={!!selectedBrand}
                brandData={selectedBrand}
                handleClose={(): void => setSelectedBrand(undefined)}
            />

            <DeleteBrandDialog
                isOpen={!!selectedDeleteBrand}
                brand={selectedDeleteBrand}
                handleCloseDialog={(): void =>
                    setSelectedDeleteBrand(undefined)
                }
            />
        </>
    );
};

export default BrandsList;
