import { type ReactElement, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Button, ChevronLeftIcon, Tooltip } from '@xeris/components';
import { transformFilterInputToOutput } from '@xeris/pages/ecoSearch/transformers';
import { NoProductsFound } from '@xeris/pages/product/Common/NoProductsFound';
import ProductEntityCard from '@xeris/pages/product/Common/ProductEntityCard/ProductEntityCard';
import { useAppSelector } from '@xeris/reducers';

import { ecoSearchHooks } from '../hooks';
import { ecoSearchSelectors } from '../selectors';
import { type EcoSearchTypes } from '../types';

import { FilterDrawer } from './FilterDrawer';
import { FilterSection } from './FilterSection';

import commonStyles from '../ecoSearch.module.scss';
import styles from './ProductBrowser.module.scss';

const Header = (): ReactElement => {
    return (
        <header className={commonStyles.pageHeader}>
            <div className={commonStyles.title}>
                <h1>X-Eco search</h1>
            </div>
        </header>
    );
};

const gpcLookup: Record<EcoSearchTypes.CategoryTypesType, string> = {
    gpc_brick: 'Brick',
    gpc_class: 'Class',
    gpc_family: 'Family',
    gpc_segment: 'Segment',
};

const SubHeader = ({
    gpcElement,
}: {
    gpcElement?: EcoSearchTypes.GenericCategoryType;
}): ReactElement => {
    const productCounts = useAppSelector(
        ecoSearchSelectors.selectProductCounts
    );
    const percentageMatched =
        productCounts.totalProductCount === 0
            ? 0
            : (productCounts.filteredProductCount * 100) /
              productCounts.totalProductCount;
    const roundedPercentageMatched = Math.round(percentageMatched * 100) / 100;
    return (
        <div className={styles.browseResult}>
            <div>
                <aside className={styles.details}>
                    <Button variant="outlined">
                        <Link to="/EcoSearch/Classifications">
                            <ChevronLeftIcon fontSize="small" />
                            Edit search
                        </Link>
                    </Button>
                </aside>
                <dl>
                    <dt>
                        {gpcLookup[gpcElement?.type ?? 'gpc_segment']} •
                        <span>{gpcElement?.id}</span>
                    </dt>
                    <Link to="/EcoSearch/Classifications">
                        <dd>{gpcElement?.name}</dd>
                    </Link>
                </dl>
            </div>
            <aside className={styles.statsComp}>
                <dl>
                    <div>
                        <Tooltip
                            title={
                                'Percentage of products within filter parameters'
                            }
                        >
                            <>
                                <dt>{roundedPercentageMatched}%</dt>
                                <dd>Range</dd>
                            </>
                        </Tooltip>
                    </div>
                    <div>
                        <dt>{productCounts.filteredProductCount}</dt>
                        <dd>Filtered</dd>
                    </div>
                    <div>
                        <dt>{productCounts.totalProductCount}</dt>
                        <dd>Available</dd>
                    </div>
                </dl>
            </aside>
        </div>
    );
};

const ProductBrowser = (): ReactElement => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const handleSetIsDrawerOpen = (newValue: boolean): void => {
        setIsDrawerOpen(newValue);
    };

    const { classificationId = '' } = useParams();
    const gpcElement = useAppSelector((state) =>
        ecoSearchSelectors.selectGpcElementWithId(state, classificationId)
    );

    const stateFilters = useAppSelector(ecoSearchSelectors.selectFilters);

    const [filters, setFilters] = useState<
        Record<string, EcoSearchTypes.FilterType>
    >({});

    useEffect(() => {
        const filtersMap = Object.fromEntries(
            stateFilters.map((filter) => [filter.id, filter])
        );
        setFilters(filtersMap);
    }, [stateFilters]);

    ecoSearchHooks.api.queries.useGetFilteredProductsQuery(
        {
            filters: Object.values(filters)
                .filter((it) => it.isActive)
                .map((filter) => transformFilterInputToOutput(filter)),
            type: gpcElement?.type ?? 'gpc_segment',
            categoryId: gpcElement?.id ?? '',
        },
        { refetchOnMountOrArgChange: true, skip: !gpcElement }
    );

    const products = useAppSelector(ecoSearchSelectors.selectFilteredProducts);

    const handleRemoveAllActiveFilters = (): void => {
        const filtersMap = Object.fromEntries(
            stateFilters.map((filter) => [filter.id, filter])
        );
        setFilters(filtersMap);
    };

    const handleRemoveActiveFilter = (filterId: string): void => {
        const modifiedNewFilter = {
            ...filters[filterId],
            value: null,
            isActive: false,
        } as EcoSearchTypes.FilterType;

        setFilters({
            ...filters,
            [filterId]: modifiedNewFilter,
        });
    };

    const handleAddActiveFilter = (
        newFilter: EcoSearchTypes.FilterType
    ): void => {
        const modifiedNewFilter = {
            ...filters[newFilter.id],
            value: newFilter.value,
            isActive: true,
        } as EcoSearchTypes.FilterType;

        setFilters({
            ...filters,
            [newFilter.id]: modifiedNewFilter,
        });
    };

    const foundProducts = products?.length && products.length > 0;

    return (
        <div className="main-content-max-width">
            <div className="dataset-page-wrapper">
                <div className="dataset-subpage-wrapper">
                    <section className={styles.EcoSearchWrapper}>
                        <Header />
                        <SubHeader gpcElement={gpcElement} />
                    </section>
                    <FilterSection
                        handleSetIsDrawerOpen={handleSetIsDrawerOpen}
                        filters={filters}
                        handleRemoveFilter={handleRemoveActiveFilter}
                    />
                </div>
            </div>

            {foundProducts ? (
                <div className={styles.productsList}>
                    {products?.map((product) => (
                        <ProductEntityCard
                            key={product.id}
                            minimalProduct={
                                product.__typename === 'Product'
                                    ? product
                                    : undefined
                            }
                            minimalMasterProduct={
                                product.__typename === 'MasterProduct'
                                    ? {
                                          ...product,
                                          products: product.products.map(
                                              (product) => ({
                                                  id: product.id,
                                                  idNumbers: [],
                                                  markets: [],
                                              })
                                          ),
                                      }
                                    : undefined
                            }
                        />
                    ))}
                </div>
            ) : (
                <NoProductsFound overrideLink="/EcoSearch" />
            )}

            <FilterDrawer
                isDrawerOpen={isDrawerOpen}
                handleSetIsDrawerOpen={handleSetIsDrawerOpen}
                handleAddActiveFilter={handleAddActiveFilter}
                handleRemoveAllActiveFilters={handleRemoveAllActiveFilters}
                handleRemoveActiveFilter={handleRemoveActiveFilter}
                filters={filters}
            />
        </div>
    );
};

export default ProductBrowser;
