import { type ReactElement } from 'react';

import { Typography } from '@mui/material';

import { type DescriptionType } from '@xeris/pages/product/types';

import './index.scss';

type DescriptionsContentProps = {
    data: DescriptionType[];
};
const DescriptionsContent = ({
    data: descriptionList,
}: DescriptionsContentProps): ReactElement => (
    <div className="datasheet-content-wrapper">
        <ul className="descriptions-list">
            {descriptionList.map((description, index) => (
                <li className="descriptions-list-item" key={index}>
                    <Typography
                        variant={'subtitle1'}
                        component={'h3'}
                        textTransform={'capitalize'}
                        sx={{ marginBottom: 2 }}
                    >
                        {description.name}
                    </Typography>
                    <Typography
                        variant={'body2'}
                        paragraph
                        className="description-text-wrapper"
                        maxWidth={'100ch'}
                    >
                        {description.value}
                    </Typography>
                </li>
            ))}
        </ul>
    </div>
);

export default DescriptionsContent;
