import { gql } from 'graphql-request';

export const getDatasetWithCategorizedProductsQuery = gql`
    query GetDatasetWithCategorizedProducts($id: String!, $exportId: String!) {
        dataset(id: $id) {
            id
            name
            totalCount: total_count
            selectedProductData: selected_product_data {
                nodes {
                    __typename
                    ... on SelectedProduct {
                        categories(export_id: $exportId) {
                            id
                            name
                        }
                        data {
                            id
                            name
                            brand {
                                id
                                name
                            }
                            groups {
                                id
                                name
                            }
                            masterProduct: master_product {
                                id
                                name
                            }
                            images {
                                url: version(size_format: THUMBNAIL)
                                objectFit: object_fit
                            }
                        }
                    }

                    ... on SelectedMasterProduct {
                        categories(export_id: $exportId) {
                            id
                            name
                        }
                        data {
                            id
                            name
                            brand {
                                id
                                name
                            }
                            groups {
                                id
                                name
                            }
                            images {
                                url: version(size_format: THUMBNAIL)
                                objectFit: object_fit
                            }
                        }
                    }
                }
            }
        }
        export(id: $exportId) {
            id
            name
            categories {
                id
                name
                children
            }
        }
    }
`;

export const categorizeProductsMutation = gql`
    mutation CategorizeProducts(
        $exportId: String!
        $categorizations: [ProductCategorisationInput!]!
    ) {
        export {
            categorise_products(
                export_id: $exportId
                categorisations: $categorizations
            )
        }
    }
`;
