import {
    type FormEventHandler,
    type ReactElement,
    type ReactNode,
} from 'react';

import { LoadingButton, type LoadingButtonProps } from '@mui/lab';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';

type FormDialogProps = {
    open: boolean;
    onClose: () => void;
    onSubmit: FormEventHandler<HTMLDivElement>;
    title: string;
    children: ReactNode;
    isLoading: boolean;
    cancelText: string;
    saveText: string;
    buttonProps?: LoadingButtonProps;
};

export const FormDialog = ({
    open,
    onClose,
    onSubmit,
    title,
    children,
    isLoading,
    cancelText,
    saveText,
    buttonProps,
}: FormDialogProps): ReactElement => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{ 'component': 'form', 'aria-label': title }}
            onSubmit={onSubmit}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent sx={{ maxWidth: '500px', width: '100vw' }}>
                {children}
            </DialogContent>
            <DialogActions>
                <Button color={'secondary'} onClick={onClose}>
                    {cancelText}
                </Button>
                <LoadingButton
                    variant={'contained'}
                    type={'submit'}
                    loading={isLoading}
                    {...buttonProps}
                >
                    {saveText}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};
