import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Checkbox, Radio } from '@mui/material';

import { Image, StarBorderIcon, StarIcon, Tooltip } from '@xeris/components';
import {
    setSelectedStatusForAll,
    setSelectedStatusForOne,
} from '@xeris/pages/product/ProductDataSelector/utilities/updateSelection';

import type { SelectedImagesDataType } from '../../types';
import SectionWrapper from '../SectionWrapper/SectionWrapper';

import './index.scss';

type MediaSectionProps = {
    images: {
        list: SelectedImagesDataType[];
        primaryImageId: string | null;
    };
    setData: (model: {
        list: SelectedImagesDataType[];
        primaryImageId: string | null;
    }) => void;
};

const MediaSection = ({ images, setData }: MediaSectionProps): ReactElement => {
    const { t } = useTranslation('product');

    const selectedCount = images.list.filter(
        (image) => image.isSelected
    ).length;

    return (
        <SectionWrapper
            selectedCount={selectedCount}
            totalCount={images.list.length}
            title={t('common.panelHeaders.media')}
            handleToggleAll={() => {
                setData(
                    setSelectedStatusForAll(
                        images,
                        selectedCount < images.list.length
                    )
                );
            }}
        >
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, 200px)',
                    gap: '1rem',
                }}
            >
                {images.list.map((image, index) => (
                    <div key={index} className="image-wrapper">
                        <Image
                            src={image.url ?? undefined}
                            alt={
                                image.title ??
                                t('dataSelector.marketingMaterial')
                            }
                            objectFit={image.objectFit ?? undefined}
                            loading="lazy"
                            width={200}
                            height={200}
                        />
                        <Checkbox
                            className="image-select"
                            checked={image.isSelected}
                            onChange={(event, data) => {
                                setData(
                                    setSelectedStatusForOne(
                                        images,
                                        image.id,
                                        data
                                    )
                                );
                            }}
                        />
                        <Tooltip
                            title={
                                image.id === images.primaryImageId
                                    ? t('dataSelector.currentPrimaryImage')
                                    : t('dataSelector.setAsPrimaryImage')
                            }
                        >
                            <Radio
                                className="image-primary"
                                checked={image.id === images.primaryImageId}
                                onChange={(event, data) => {
                                    // Only allow setting the primary image, not unset it, as we always need to have one
                                    if (data) {
                                        setData({
                                            primaryImageId: image.id,
                                            list: images.list.map((i) => ({
                                                ...i,
                                                isSelected:
                                                    i.id === image.id
                                                        ? true
                                                        : i.isSelected,
                                            })),
                                        });
                                    }
                                }}
                                color="secondary"
                                checkedIcon={<StarIcon fontSize="small" />}
                                icon={<StarBorderIcon fontSize="small" />}
                            />
                        </Tooltip>
                    </div>
                ))}
            </Box>
        </SectionWrapper>
    );
};

export default MediaSection;
