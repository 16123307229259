import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Chip } from '@mui/material';

import { CircleIcon } from '@xeris/components';
import { useWhoami } from '@xeris/hooks';
import { useAppSelector } from '@xeris/reducers';

import { datasetSelectors } from '../../reducers';
import { generateDatasetUrl } from '../../utilities';

import styles from '../DatasetList.module.scss';

type DatasetNameProps = {
    dataset: {
        id: string;
        name: string;
        totalCount: number;
        owner: {
            id: string;
            name: string;
        };
    };
};

const DatasetName = ({ dataset }: DatasetNameProps): ReactElement => {
    const { t } = useTranslation('dataset');

    const { userId } = useWhoami();
    const activeDatasetId = useAppSelector(
        datasetSelectors.active.selectActiveId
    );

    const createdBy =
        userId === dataset.owner.id ? t('common.you') : dataset.owner.name;

    return (
        <Link to={generateDatasetUrl(dataset)} className={styles.datasetName}>
            <h3 className={styles.name}>{dataset.name}</h3>
            <div className={styles.details}>
                <span>
                    <span>{dataset.totalCount}</span>{' '}
                    {t('datasetList.products')}
                </span>
                <CircleIcon fontSize="small" />
                <span>
                    {t('datasetList.createdBy', {
                        usernameOrYou: createdBy,
                    })}
                </span>
                {dataset.id === activeDatasetId && (
                    <Chip
                        color={'primary'}
                        size={'small'}
                        label={t('common.active')}
                    />
                )}
            </div>
        </Link>
    );
};

export default DatasetName;
