import { type ForwardedRef, forwardRef, type ReactElement } from 'react';
import { type Link } from 'react-router-dom';

import {
    Button as MaterialButton,
    type ButtonProps as MuiButtonProps,
} from '@mui/material';

import Tooltip from '../Tooltip';

import styles from './Button.module.scss';

export type ButtonShapeType = 'squared' | 'rounded';

const SHAPE = {
    squared: styles.squared,
    rounded: styles.rounded,
};

export type ButtonVariantsType =
    | 'solid'
    | 'solidNeutral'
    | 'outlined'
    | 'outlinedNeutral'
    | 'text'
    | 'textNeutral'
    | 'textError'
    | 'solidError'
    | 'solidWarning'
    | 'textWarning'
    | 'outlinedWarning';

const VARIANTS = {
    solid: styles.solid,
    solidNeutral: styles.solidNeutral,
    outlined: styles.outlined,
    outlinedNeutral: styles.outlinedNeutral,
    text: styles.text,
    textNeutral: styles.textNeutral,
    textError: styles.textError,
    solidError: styles.solidError,
    solidWarning: styles.solidWarning,
    textWarning: styles.textWarning,
    outlinedWarning: styles.outlinedWarning,
};

type WrappedButtonProps = Omit<MuiButtonProps, 'color'>;

export type ButtonProps = Omit<WrappedButtonProps, 'variant'> & {
    shape?: ButtonShapeType;
    variant?: ButtonVariantsType;
    uppercase?: boolean;
    minWidth?: number;
    minHeight?: number;
    tooltipText?: string;
    // There should no need to add component and to, it should inherit from ButtonProps,
    // but it does not work, I think because there are several types of buttons and we have to map
    // to the correct button props, but I couldn't make it work
    component?: typeof Link;
    to?: string;
    dataTestId?: string;
};

const Button = forwardRef(function Button(
    {
        children,
        shape = 'squared',
        variant = 'solid',
        uppercase = false,
        minWidth = 0,
        minHeight = 0,
        disabled = false,
        className,
        tooltipText,
        style,
        dataTestId,
        ...otherProps
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
): ReactElement {
    const rootClassNames = [styles.button];
    const buttonClassNames = [
        SHAPE[shape],
        VARIANTS[variant],
        styles.defaultStyling,
    ];

    if (className) rootClassNames.push(className);
    if (uppercase) buttonClassNames.push(styles.uppercase);
    if (disabled) buttonClassNames.push(styles.disabled);

    return (
        <div className={rootClassNames.join(' ')}>
            <Tooltip title={tooltipText}>
                <MaterialButton
                    ref={ref}
                    {...otherProps}
                    disabled={disabled}
                    className={buttonClassNames.join(' ')}
                    style={{
                        ...style,
                        minWidth: minWidth,
                        minHeight: minHeight,
                    }}
                    data-testid={dataTestId}
                >
                    {children}
                </MaterialButton>
            </Tooltip>
        </div>
    );
});

export default Button;
