import { gql } from 'graphql-request';

const exportJobFields = gql`
    fragment ExportJobFields on ExportJob {
        id
        user {
            id
            name
            username
        }
        dataset {
            id
            name
        }
        export {
            id
            name
            type {
                name
            }
        }
        startTime: start_time
        endTime: end_time
        status
        message
        downloadUri: download_uri
        productProgress: product_progress {
            total
            finished
            processing
            succeeded
            failed
            skipped
            percent
        }
        productStatus: product_status {
            product {
                id
                name
                brand {
                    id
                    name
                }
                masterProduct: master_product {
                    id
                    name
                }
                images {
                    id
                    url: version(size_format: THUMBNAIL)
                    title
                    objectFit: object_fit
                }
            }
            status
            message
        }
        masterProductStatus: master_product_status {
            masterProduct: master_product {
                id
                name
                brand {
                    id
                    name
                }
                images {
                    id
                    url: version(size_format: THUMBNAIL)
                    title
                    objectFit: object_fit
                }
            }
            status
            message
        }
    }
`;

export const getExportJobQuery = gql`
    query GetExportJob($id: String!) {
        exportJob: export_job(id: $id) {
            ...ExportJobFields
        }
    }
    ${exportJobFields}
`;

export const createExportJobMutation = gql`
    mutation CreateExportJob(
        $exportId: String!
        $datasetId: String!
        $options: [ExportOptionValue!]
    ) {
        export {
            create: create_export_job(
                export_id: $exportId
                dataset_id: $datasetId
                options: $options
            ) {
                id
            }
        }
    }
`;

const baseExportJobFields = gql`
    fragment BaseExportJobFields on ExportJob {
        id
        user {
            id
            name
            username
        }
        dataset {
            name
            id
        }
        export {
            id
            name
            type {
                name
            }
        }
        startTime: start_time
        endTime: end_time
        status
        message
        downloadUri: download_uri
    }
`;

export const getExportJobsQuery = gql`
    query GetExportJobs {
        exportJobs: export_jobs {
            ...BaseExportJobFields
        }
    }
    ${baseExportJobFields}
`;

export const getExportsQuery = gql`
    query GetExports($organizationId: String) {
        exports(organization: $organizationId) {
            id
            name
            description
            type {
                id
                name
            }
            organizationId: organization
            categories {
                id
                name
                children
            }
            options {
                __typename
                key
                label
                required
                askUser
                ... on BooleanConfigurationOption {
                    defaultBoolean
                }
                ... on StringConfigurationOption {
                    defaultString
                }
                ... on IntConfigurationOption {
                    defaultInt
                }
                ... on SelectConfigurationOption {
                    defaultOption
                    values {
                        label
                        value
                    }
                }
                ... on LanguageConfigurationOption {
                    languages {
                        id
                        language
                    }
                    defaultLanguage
                }
            }
        }
    }
`;
