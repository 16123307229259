import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { type TFunction } from 'i18next';

import {
    type TabProviderTabType,
    TabsProvider,
    Typography,
} from '@xeris/components';
import { useFeatureFlag, useQueryParams } from '@xeris/hooks';

import ListView from './components/ListView';
import { type TabIdsType } from './types';

type TabItemType = Omit<TabProviderTabType, 'id'> & { id: TabIdsType };

const getTabList = (t: TFunction<'dataset'>): TabItemType[] => [
    {
        id: 'all',
        Label: <span>{t('datasetList.allDatasets')}</span>,
        Component: <div />,
    },
    {
        id: 'private',
        Label: <span>{t('datasetList.yourDatasets')}</span>,
        Component: <div />,
    },
    {
        id: 'shared',
        Label: <span>{t('datasetList.sharedDatasets')}</span>,
        Component: <div />,
    },
];

const DatasetList = (): ReactElement => {
    const navigate = useNavigate();
    const { featureFlagVisible } = useFeatureFlag('shareDataset');
    const { t } = useTranslation('dataset');

    const tabIdFromUrl = useQueryParams('filter');

    const tabList = getTabList(t);

    const isValidTabId = tabList.some((tab) => tab.id === tabIdFromUrl);

    const handleChangeTabId = (newActiveTabId: string): void => {
        navigate(`/dataset?filter=${newActiveTabId}`);
    };

    return (
        <>
            <header>
                <Typography variant="h1" gutterBottom>
                    {t('datasetList.datasets')}
                </Typography>
                {featureFlagVisible && (
                    <TabsProvider
                        tabList={tabList}
                        activeTabId={isValidTabId ? tabIdFromUrl : 'all'}
                        handleChangeTabId={handleChangeTabId}
                    />
                )}
            </header>

            <ListView />
        </>
    );
};

export default DatasetList;
