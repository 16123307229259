import { type ReactElement } from 'react';

import { Button, CloseIcon, Loading, SlidingDrawer } from '@xeris/components';
import { useAppSelector } from '@xeris/reducers';

import { ecoSearchSelectors } from '../selectors';
import { type EcoSearchTypes } from '../types';

import { FilterFactory } from './FilterFactory';

import styles from './ProductBrowser.module.scss';

type FilterDrawerProps = {
    isDrawerOpen: boolean;
    handleSetIsDrawerOpen: (newValue: boolean) => void;
    handleAddActiveFilter: (newFilter: EcoSearchTypes.FilterType) => void;
    handleRemoveActiveFilter: (filterId: string) => void;
    handleRemoveAllActiveFilters: () => void;
    filters: Record<string, EcoSearchTypes.FilterType>;
};

export const FilterDrawer = ({
    isDrawerOpen,
    handleSetIsDrawerOpen,
    handleAddActiveFilter,
    handleRemoveActiveFilter,
    handleRemoveAllActiveFilters,
    filters,
}: FilterDrawerProps): ReactElement => {
    const matchingProducts = useAppSelector(
        ecoSearchSelectors.selectProductCounts
    ).filteredProductCount;
    const hasFilters = Object.values(filters).length > 0;
    return (
        <SlidingDrawer
            show={isDrawerOpen}
            variant="fullscreen"
            handleClose={(): void => handleSetIsDrawerOpen(false)}
        >
            <div className={styles.filterDrawer}>
                <div className={styles.container}>
                    <header className={styles.header}>
                        <h3 className={styles.title}>Filter products</h3>
                        <div
                            className={styles.filterClose}
                            onClick={(): void => handleSetIsDrawerOpen(false)}
                        >
                            <CloseIcon />
                        </div>
                    </header>
                    <div>
                        {hasFilters ? (
                            Object.values(filters).map((filter) => (
                                <FilterFactory
                                    filter={filter}
                                    key={filter.id}
                                    handleAddActiveFilter={
                                        handleAddActiveFilter
                                    }
                                    handleRemoveActiveFilter={
                                        handleRemoveActiveFilter
                                    }
                                />
                            ))
                        ) : (
                            <Loading loading={true} />
                        )}
                    </div>
                </div>
                <footer className={styles.filterApply}>
                    <Button
                        variant="outlinedNeutral"
                        className={styles.btnFull}
                        onClick={handleRemoveAllActiveFilters}
                    >
                        Clear all filters
                    </Button>
                    <Button
                        className={styles.btnFull}
                        onClick={(): void => handleSetIsDrawerOpen(false)}
                    >
                        Show products ({matchingProducts})
                    </Button>
                </footer>
            </div>
        </SlidingDrawer>
    );
};
