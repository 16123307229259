import { type ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    Alert,
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    Drawer,
    Slide,
    Snackbar,
} from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';

import { Typography } from '@xeris/components';
import {
    AutocompleteInput,
    AutocompleteMultipleInput,
    SelectInput,
} from '@xeris/components/forms';
import { connectionsApi, organizationsApi } from '@xeris/pages/admin/api';
import { useActiveOrganizationId } from '@xeris/pages/admin/hooks';

import {
    type Connection,
    type ConnectionsTabVariantType,
    type UiConnectionType,
} from '../types/connectionsTypes';
import { getAvailableOrganizations } from '../utilities/getAvailableOrganizations';

import { connectionSchema, type FormData } from './common/connectionSchema';
import AccessToBrandsField from './common/fields/AccessToBrandsField';
import AccessToMarketsField from './common/fields/AccessToMarketsField';
import { accessTypes } from './common/types';
import { useGetFormData } from './common/useGetFormData';
import { convertFormDataToRules } from './common/utilities';

type NewConnectionFormProps = {
    isOpen: boolean;
    handleClose: () => void;
    connectionType: ConnectionsTabVariantType;
    uiConnectionType: UiConnectionType;
    connectionList: Connection[];
};

const NewConnectionForm = ({
    isOpen,
    handleClose,
    uiConnectionType,
    connectionType,
    connectionList,
}: NewConnectionFormProps): ReactElement => {
    const { t } = useTranslation('administration');

    const [isSubmitErrorOpen, setIsSubmitErrorOpen] = useState(false);

    const activeOrganizationId = useActiveOrganizationId();

    const { data } = organizationsApi.useGetOrganizationsQuery({});

    const organizationList = data?.organizations ?? [];

    const formData = useGetFormData(
        activeOrganizationId,
        null,
        connectionType,
        false
    );

    const [createConnection, { error, isLoading }] =
        connectionsApi.useCreateConnectionMutation();

    const { control, handleSubmit, reset } = useForm<FormData>({
        resolver: yupResolver(connectionSchema),
        defaultValues: {
            configs: [],
            accessRuleType: 'organization',
            accessToMarkets: [],
            accessToBrands: [],
        },
    });

    const handleSubmitConnection = async (data: FormData): Promise<void> => {
        if (!activeOrganizationId) return;

        const producerId =
            connectionType === 'channels'
                ? activeOrganizationId
                : data.tradingPartner;

        const consumerId =
            connectionType === 'channels'
                ? data.tradingPartner
                : activeOrganizationId;

        const rules = convertFormDataToRules(data, producerId, 'ALLOW');
        const exportIds = data.configs.map(({ id }) => id);

        await createConnection({
            producerId: producerId,
            consumerId: consumerId,
            status: 'ACTIVE',
            rules: rules,
            hasRules: connectionType === 'channels' && rules.length > 0,
            exportIds: exportIds,
            hasExports: connectionType === 'channels' && exportIds.length > 0,
        });

        handleClose();
        reset();
    };

    useEffect(() => {
        if (error) {
            setIsSubmitErrorOpen(true);
        }
    }, [error, setIsSubmitErrorOpen]);

    return (
        <Drawer
            open={isOpen}
            onClose={handleClose}
            PaperProps={{
                'component': 'form',
                'aria-label': 'Create connection',
            }}
            onSubmit={handleSubmit(handleSubmitConnection)}
        >
            <DialogTitle>
                {t(`connections.new.${uiConnectionType}`)}
            </DialogTitle>
            <DialogContent dividers sx={{ maxWidth: '500px' }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1.5rem',
                    }}
                >
                    <Typography variant={'body1'}>
                        {t(`connections.newTradingPartner.${connectionType}`)}
                    </Typography>
                    <AutocompleteInput
                        fieldName="tradingPartner"
                        label={t('connections.tradingPartner')}
                        control={control}
                        options={getAvailableOrganizations(
                            connectionList,
                            organizationList,
                            connectionType
                        )}
                    />
                    {connectionType === 'channels' && (
                        <AutocompleteMultipleInput
                            fieldName="configs"
                            label={t('connections.export')}
                            options={formData.exportConfigList}
                            control={control}
                            disableCloseOnSelect
                        />
                    )}
                    {connectionType === 'channels' && (
                        <>
                            <Typography
                                variant={'h4'}
                                component={'h3'}
                                marginBlockStart={4}
                            >
                                {t('connections.productAccess')}
                            </Typography>
                            <SelectInput
                                control={control}
                                fieldName={'accessRuleType'}
                                options={accessTypes.map((accessType) => ({
                                    id: accessType,
                                    label: t(
                                        `connections.accessType.${accessType}`
                                    ),
                                }))}
                            />
                            <AccessToBrandsField
                                control={control}
                                fieldName={'accessToBrands'}
                                label={t('connections.brands')}
                                brandList={formData.brandList}
                            />
                            <AccessToMarketsField
                                control={control}
                                fieldName={'accessToMarkets'}
                                label={t('connections.markets')}
                                marketList={formData.marketList}
                            />
                        </>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                {handleClose && (
                    <Button
                        variant={'text'}
                        color={'secondary'}
                        onClick={handleClose}
                    >
                        {t('common.cancel')}
                    </Button>
                )}
                <Button
                    disabled={isLoading}
                    type={'submit'}
                    variant={'contained'}
                >
                    {t('connections.createConnection')}
                </Button>
            </DialogActions>
            <Snackbar
                open={isSubmitErrorOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                TransitionComponent={Slide}
            >
                <Alert
                    severity="error"
                    elevation={2}
                    onClose={() => setIsSubmitErrorOpen(false)}
                >
                    {t('connections.error')}
                </Alert>
            </Snackbar>
        </Drawer>
    );
};

export default NewConnectionForm;
