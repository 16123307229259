import { type ReactElement, type SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { ListItemIcon, ListItemText } from '@mui/material';

import { type TFunction } from 'i18next';

import {
    DeleteIcon,
    EditIcon,
    SelectMenu,
    type SelectMenuItemType,
} from '@xeris/components';

import { type OrganizationTypes } from '../types';

type Action = 'edit' | 'delete';

const getActionMenuList = (
    t: TFunction<'administration'>
): SelectMenuItemType<Action>[] => {
    return [
        {
            key: 'edit',
            value: 'edit',
            content: (
                <>
                    <ListItemIcon>
                        <EditIcon fontSize={'small'} />
                    </ListItemIcon>
                    <ListItemText
                        primary={t(
                            'organization.editOrganization.editOrganization'
                        )}
                    />
                </>
            ),
        },
        {
            key: 'delete',
            value: 'delete',
            content: (
                <>
                    <ListItemIcon>
                        <DeleteIcon fontSize={'small'} />
                    </ListItemIcon>
                    <ListItemText
                        primary={t(
                            'organization.deleteOrganization.deleteOrganization'
                        )}
                    />
                </>
            ),
        },
    ];
};

type OrganizationActionsProps = {
    organization: OrganizationTypes.OrganizationType;
    setSelectedOrganization: (
        organization: OrganizationTypes.OrganizationType
    ) => void;
    setSelectedDeleteOrganization: (
        organization: OrganizationTypes.OrganizationType
    ) => void;
};

const OrganizationActions = ({
    organization,
    setSelectedOrganization,
    setSelectedDeleteOrganization,
}: OrganizationActionsProps): ReactElement | null => {
    const { t } = useTranslation('administration');

    const handleMenuAction = (event: SyntheticEvent, value?: Action): void => {
        event.stopPropagation();
        if (value === 'edit') {
            setSelectedOrganization(organization);
        }
        if (value === 'delete') {
            setSelectedDeleteOrganization(organization);
        }
    };

    const actionMenuList = getActionMenuList(t);

    if (!organization) {
        return null;
    }

    return (
        <SelectMenu
            menuItemList={actionMenuList}
            handleSelect={handleMenuAction}
        />
    );
};

export default OrganizationActions;
