import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';

import {
    Button,
    DeleteIcon,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@xeris/components';
import { organizationsApi } from '@xeris/pages/admin/api';

import { type OrganizationActionDialogProps } from './types';

const DeleteOrganizationDialog = ({
    isOpen,
    handleClose,
    organization,
}: OrganizationActionDialogProps): ReactElement | null => {
    const { t } = useTranslation('administration');

    const [deleteOrganizationMutation, { isLoading }] =
        organizationsApi.useDeleteOrganizationMutation();

    const handleDeleteOrganization = async (): Promise<void> => {
        if (!organization) {
            return;
        }
        await deleteOrganizationMutation({ id: organization.id }).unwrap();

        handleClose();
    };

    return (
        <Dialog onClose={handleClose} maxWidth={'sm'} fullWidth open={isOpen}>
            <DialogTitle>
                {t('organization.deleteOrganization.deleteNamedOrganization', {
                    organizationName: organization?.name,
                })}
            </DialogTitle>
            <DialogContent>
                <Typography>
                    {t(
                        'organization.deleteOrganization.deletingTheOrganization'
                    )}{' '}
                    <strong>{organization?.name}</strong>{' '}
                    {t(
                        'organization.deleteOrganization.willPermanentlyRemoveBrands'
                    )}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="textNeutral" onClick={handleClose}>
                    {t('brand.common.cancel')}
                </Button>
                <LoadingButton
                    variant={'contained'}
                    color={'error'}
                    onClick={handleDeleteOrganization}
                    disableElevation
                    loading={isLoading}
                    startIcon={<DeleteIcon />}
                >
                    {t('brand.common.delete')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteOrganizationDialog;
