import { type ReactElement, type ReactNode } from 'react';

import { Box } from '@mui/material';

import { Footer } from '@xeris/pages/product/ProductDataSelector/Footer/Footer';
import { type HandleAddToDatasetData } from '@xeris/pages/product/ProductDataSelector/types';
import { type NewDataset } from '@xeris/pages/product/types';

type InlineDialogWrapperProps = {
    children?: ReactNode;
    handleAddToDataset: (data: HandleAddToDatasetData) => void;
    newDataset: NewDataset;
    handleSetNewDataset: (newDataset: NewDataset) => void;
    activeDatasetName: string | undefined;
    setActiveDatasetId: (datasetId: string | null) => void;
    activeDatasetId: string | null;
    isEditing?: boolean;
    datasetSelectorDisabled?: boolean;
    configurationSetName?: string;
    isError: boolean;
};

const InlineDialogWrapper = ({
    children,
    handleAddToDataset,
    newDataset,
    handleSetNewDataset,
    activeDatasetName,
    setActiveDatasetId,
    activeDatasetId,
    isEditing,
    datasetSelectorDisabled,
    configurationSetName,
    isError,
}: InlineDialogWrapperProps): ReactElement => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            }}
        >
            <Box sx={{ flexGrow: 1 }}>{children}</Box>
            <Box
                sx={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'background.default',
                    boxShadow: '0px -4px 8px rgba(29, 44, 72, 0.08);',
                }}
            >
                <Footer
                    activeDatasetName={activeDatasetName}
                    isInlineDialog={true}
                    handleAddToDataset={handleAddToDataset}
                    newDataset={newDataset}
                    handleSetNewDataset={handleSetNewDataset}
                    setActiveDatasetId={setActiveDatasetId}
                    activeDatasetId={activeDatasetId}
                    isEditing={isEditing}
                    datasetSelectorDisabled={datasetSelectorDisabled}
                    configurationSetName={configurationSetName}
                    isError={isError}
                />
            </Box>
        </Box>
    );
};

export default InlineDialogWrapper;
