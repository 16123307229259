import { type ReactElement } from 'react';

import { type BasicFeature } from '@xeris/pages/product/types';

import OptionCard from './OptionCard';

import styles from './BasicPanel.module.scss';

type FeatureOptionsProps = {
    feature: BasicFeature;
};

const FeatureOptionList = ({ feature }: FeatureOptionsProps): ReactElement => {
    return (
        <div className={styles.featureOptions}>
            <div className={styles.basicOptions}>
                {feature.options?.map((option) => (
                    <div className={styles.featureOption} key={option.id}>
                        <OptionCard featureId={feature.id} option={option} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FeatureOptionList;
