import { gql } from 'graphql-request';

import {
    baseDatasetFields,
    baseSelectedProductDataFields,
} from '../datasetQueryFragments';

export const createDatasetMutation = gql`
    mutation CreateDataset(
        $name: String!
        $description: String
        $selected_products: [SelectedProductInput!]
        $selected_master_products: [SelectedMasterProductInput!]
    ) {
        dataset {
            create(
                dataset: {
                    name: $name
                    description: $description
                    selected_products: $selected_products
                    selected_master_products: $selected_master_products
                }
            ) {
                ...BaseDatasetFields
                ...BaseSelectedProductDataFields
            }
        }
    }
    ${baseDatasetFields}
    ${baseSelectedProductDataFields}
`;

export const editDatasetQuery = gql`
    mutation EditDataset($id: String!, $name: String, $description: String) {
        dataset {
            update(
                dataset: { id: $id, name: $name, description: $description }
            ) {
                ...BaseDatasetFields
            }
        }
    }
    ${baseDatasetFields}
`;

export const duplicateDatasetMutation = gql`
    mutation DuplicateDataset(
        $id: String!
        $name: String!
        $description: String
    ) {
        dataset {
            copy(id: $id, new_name: $name, new_description: $description) {
                ...BaseDatasetFields
                ...BaseSelectedProductDataFields
            }
        }
    }
    ${baseDatasetFields}
    ${baseSelectedProductDataFields}
`;

export const deleteDatasetMutation = gql`
    mutation DeleteDataset($id: String!) {
        dataset {
            delete(id: $id) {
                id
                deleted
            }
        }
    }
`;

export const getDatasetQuery = gql`
    query GetDataset($id: String!) {
        dataset(id: $id) {
            id
            name
            description
            created
            lastModified: last_modified
            lastProductUpdate: last_product_update
            lastExported: last_exported
            previewImages: preview_images(limit: 1)
            brands {
                id
                name
            }
            totalCount: total_count
            missingProductCount: missing_product_count
            owner {
                id
                name
                username
                organization {
                    id
                    name
                    type
                }
            }
            privilege
            sharedWith: shared_with {
                privilege
                user {
                    id
                    name
                    username
                    organization {
                        id
                        name
                        type
                    }
                }
            }
        }
    }
`;

export const getDatasetListQuery = gql`
    query DatasetList {
        datasets {
            ...BaseDatasetFields
        }
    }
    ${baseDatasetFields}
`;

export const getActiveDatasetQuery = gql`
    query ActiveDataset($id: String!) {
        dataset(id: $id) {
            ...BaseDatasetFields
            ...BaseSelectedProductDataFields
        }
    }
    ${baseDatasetFields}
    ${baseSelectedProductDataFields}
`;

export const getFullDatasetQuery = gql`
    query FullDataset($id: String!, $first: Int, $cursor: String) {
        dataset(id: $id) {
            ...BaseDatasetFields
            selectedProductData: selected_product_data(
                first: $first
                cursor: $cursor
            ) {
                cursor
                nodes {
                    __typename
                    id
                    ... on SelectedProduct {
                        data {
                            lastUpdated: last_updated
                        }
                        selectedData: selected_data {
                            id
                            name
                            masterProduct: master_product {
                                __typename
                                id
                                name
                                lastUpdated: last_updated
                                images {
                                    id
                                    objectFit: object_fit
                                    url: version(size_format: THUMBNAIL)
                                }
                                brand {
                                    id
                                    name
                                }
                            }
                        }
                    }
                    ... on SelectedMasterProduct {
                        data {
                            id
                            name
                            lastUpdated: last_updated
                            brand {
                                id
                                name
                            }
                            images {
                                id
                                objectFit: object_fit
                                url: version(size_format: THUMBNAIL)
                            }
                        }
                        configurationSets: configuration_sets {
                            id
                            name
                            features {
                                id
                                options {
                                    id
                                }
                            }
                            selectedData: selected_data {
                                images {
                                    id
                                    objectFit: object_fit
                                    url: version(size_format: THUMBNAIL)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    ${baseDatasetFields}
`;
