import {
    type ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorPage } from '@xeris/components';
import { datasetProductsApi, datasetsApi } from '@xeris/pages/dataset/api';
import { datasetActions } from '@xeris/pages/dataset/reducers';
import ContentForm from '@xeris/pages/product/ProductDataSelector/ContentForm/ContentForm';
import { type SelectedDataRefType } from '@xeris/pages/product/ProductDataSelector/types';
import { getProductsDataSelection } from '@xeris/pages/product/ProductDataSelector/utilities/getDataSelection';
import { getOptionalProductData } from '@xeris/pages/product/ProductDataSelector/utilities/getOptionalProductData';
import { useAppDispatch, useAppSelector } from '@xeris/reducers';
import { datasetSelectors } from '@xeris/selectors';

import DialogWrapper from './DialogWrapper/DialogWrapper';
import Loader from './Loader/Loader';

const initialNewDataset = {
    name: '',
    description: '',
    isNameValid: false,
    attemptedSubmitted: false,
    showForm: false,
};

type VariantProductDataSelectorProps = {
    productIds: string[];
    datasetId?: string;
    handleSelectionClose: () => void;
};

const VariantProductDataSelector = ({
    productIds,
    handleSelectionClose,
    datasetId,
}: VariantProductDataSelectorProps): ReactElement => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation('product');

    const selectedData = useRef<SelectedDataRefType>({});

    const [createDataset, createDatasetResult] =
        datasetsApi.useCreateDatasetMutation();

    const [addProductsToDataset, addProductsToDatasetResult] =
        datasetProductsApi.useAddProductsToDatasetMutation();

    const [isDialogOpen, setIsDialogOpen] = useState(true);

    const [newDataset, setNewDataset] = useState({ ...initialNewDataset });

    const activeDataset = useAppSelector(datasetSelectors.active.selectDataset);

    const [activeDatasetId, setActiveDatasetId] = useState<string | null>(
        datasetId ?? activeDataset?.id ?? null
    );

    const { data, isFetching, isError, refetch } =
        datasetProductsApi.useGetProductsWithSelectedDataQuery({
            datasetId: activeDatasetId ?? '',
            hasDatasetId: !!activeDatasetId,
            productIds: productIds,
        });

    // Remove products that does not exist
    const products = useMemo(
        () =>
            data?.products.filter(
                (product): product is NonNullable<typeof product> => !!product
            ) ?? [],
        [data]
    );

    const dataSelection = useMemo(
        () => getProductsDataSelection(products),
        [products]
    );

    useEffect(() => {
        if (!activeDataset) {
            setNewDataset((previousState) => ({
                ...previousState,
                showForm: true,
            }));
        }
    }, [activeDataset]);

    const handleCloseDialog = useCallback((): void => {
        if (handleSelectionClose) handleSelectionClose();
        setIsDialogOpen(false);
    }, [handleSelectionClose]);

    const handleAddToDataset = useCallback((): Promise<unknown> => {
        if (newDataset.showForm && !newDataset.isNameValid) {
            setNewDataset({ ...newDataset, attemptedSubmitted: true });
            return Promise.resolve();
        }

        const selectedProducts = Object.entries(selectedData.current)
            .filter(([_, data]) => data.isSelected)
            .map(([productId]) => ({
                id: productId,
                ...getOptionalProductData(selectedData.current[productId]),
            }));

        // Remove product that was selected, but have been removed
        const removedProductIds = products
            .filter(
                (product) =>
                    product.selectedData &&
                    !selectedData.current[product.id]?.isSelected
            )
            .map((product) => product.id);

        if (newDataset.showForm) {
            // Create a new dataset with this product
            return createDataset({
                name: newDataset.name,
                description: newDataset.description,
                selected_products: selectedProducts,
                selected_master_products: null,
            }).then((response) => {
                if ('data' in response && response.data.dataset.create) {
                    setNewDataset({ ...initialNewDataset });
                    dispatch(
                        datasetActions.changeActiveDataset(
                            response.data.dataset.create.id
                        )
                    );
                }
            });
        }

        if (!activeDatasetId) {
            return Promise.resolve();
        }

        // Add/remove product to the dataset
        return addProductsToDataset({
            data: {
                id: activeDatasetId,
                selected_products: selectedProducts,
                selected_master_products: null,
            },
            removeProducts: removedProductIds.length > 0,
            datasetId: activeDatasetId,
            productIdsToRemove: removedProductIds,
        });
    }, [
        activeDatasetId,
        addProductsToDataset,
        createDataset,
        dispatch,
        newDataset,
        products,
    ]);

    const setActiveDataset = useCallback(
        (datasetId: string | null): void => setActiveDatasetId(datasetId),
        [setActiveDatasetId]
    );

    useEffect(() => {
        if (
            createDatasetResult.isSuccess ||
            addProductsToDatasetResult.isSuccess
        ) {
            handleCloseDialog();
        }
    }, [
        createDatasetResult,
        addProductsToDatasetResult,
        dispatch,
        activeDatasetId,
        handleCloseDialog,
    ]);

    return (
        <DialogWrapper
            isDialogOpen={isDialogOpen}
            handleAddToDataset={handleAddToDataset}
            handleClose={handleCloseDialog}
            newDataset={newDataset}
            handleSetNewDataset={setNewDataset}
            activeDatasetName={activeDataset?.name}
            setActiveDatasetId={setActiveDataset}
            activeDatasetId={activeDatasetId}
            isError={
                createDatasetResult.isError ||
                addProductsToDatasetResult.isError
            }
        >
            {isFetching && <Loader />}
            {!isFetching && isError && (
                <ErrorPage
                    title={t('errors.failedToLoad')}
                    actionText={t('errors.tryAgain')}
                    onClick={() => refetch()}
                />
            )}
            {!!data && !isError && !isFetching && (
                <ContentForm
                    selectedDataRef={selectedData}
                    data={dataSelection}
                    products={products}
                    newDataset={newDataset}
                    handleSetNewDataset={setNewDataset}
                />
            )}
        </DialogWrapper>
    );
};

export default VariantProductDataSelector;
