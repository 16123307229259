import { type ReactElement } from 'react';
import { type Control, type Path, useWatch } from 'react-hook-form';

import { Box } from '@mui/material';

import { AutocompleteMultipleInput } from '@xeris/components/forms';

import { type FormData } from '../connectionSchema';

type TradingPartnerProps = {
    fieldName: Path<FormData>;
    label: string;
    control: Control<FormData>;
    brandList: {
        id: string;
        label: string;
    }[];
};

function AccessToBrandsField({
    fieldName,
    label,
    control,
    brandList,
}: TradingPartnerProps): ReactElement | null {
    const accessRuleType = useWatch({ control, name: 'accessRuleType' });

    return (
        <Box display={accessRuleType === 'brands' ? 'initial' : 'none'}>
            <AutocompleteMultipleInput
                showError
                fieldName={fieldName}
                control={control}
                label={label}
                options={brandList}
                disableCloseOnSelect
            />
        </Box>
    );
}

export default AccessToBrandsField;
