import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';

import { useLocale } from '@xeris/hooks';
import {
    type DimentionVariantsType,
    type PackagingUnitType,
    type PackagingVariantsTypes,
} from '@xeris/pages/product/types';

import './index.scss';

const unitObject = {
    weight: { unit: 'kg', digits: 6 },
    height: { unit: 'mm', digits: 6 },
    width: { unit: 'mm', digits: 6 },
    length: { unit: 'mm', digits: 6 },
    volume: { unit: 'm³', digits: 6 },
    quantity: { unit: '', digits: 6 },
} satisfies Record<
    DimentionVariantsType | 'quantity',
    { unit: string; digits: number }
>;

type PackagingContentLineProps = {
    title: DimentionVariantsType | 'quantity';
    value: string | number | null;
};

const PackagingContentLine = ({
    title,
    value,
}: PackagingContentLineProps): ReactElement | null => {
    const { t } = useTranslation('product');
    const locale = useLocale();

    if (!value) {
        return null;
    }

    const { unit, digits } = unitObject[title];

    return (
        <>
            <dt>
                <Typography variant={'label'}>
                    {t(`datasheet.packaging.dimensions.${title}`)}
                </Typography>
            </dt>
            <dd>
                <Typography variant={'body2'}>
                    {typeof value === 'string'
                        ? value
                        : Intl.NumberFormat(locale, {
                              maximumFractionDigits: digits,
                          }).format(value)}{' '}
                    <span>{unit}</span>
                </Typography>
            </dd>
        </>
    );
};

type PackagingSectionProps = {
    title: string;
    data: PackagingUnitType | null;
};

const PackagingSection = ({
    data,
    title,
}: PackagingSectionProps): ReactElement | null => {
    if (!data) return null;

    return (
        <div>
            <Typography
                variant={'h4'}
                color={'text.secondary'}
                component={'h3'}
                textTransform={'capitalize'}
                sx={{ marginBottom: 2 }}
            >
                {title}
            </Typography>
            <dl className="datasheet-list-col">
                <PackagingContentLine
                    title={'height'}
                    value={data.dimensions.height}
                />
                <PackagingContentLine
                    title={'length'}
                    value={data.dimensions.length}
                />
                <PackagingContentLine
                    title={'width'}
                    value={data.dimensions.width}
                />
                <PackagingContentLine
                    title={'volume'}
                    value={data.dimensions.volume}
                />
                <PackagingContentLine
                    title={'weight'}
                    value={data.dimensions.weight}
                />
                <PackagingContentLine
                    title={'quantity'}
                    value={data.quantityWithUnit}
                />
            </dl>
        </div>
    );
};

type PackagingProps = {
    data: PackagingVariantsTypes;
};

const Packaging = ({ data: packaging }: PackagingProps): ReactElement => {
    const { t } = useTranslation('product');

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
            <PackagingSection
                title={t('datasheet.packaging.units.consumer')}
                data={packaging.consumerUnit}
            />
            <PackagingSection
                title={t('datasheet.packaging.units.distribution')}
                data={packaging.distributionUnit}
            />
            <PackagingSection
                title={t('datasheet.packaging.units.transportation')}
                data={packaging.transportationUnit}
            />
        </Box>
    );
};

export default Packaging;
