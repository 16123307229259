import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';

import {
    Button,
    DeleteIcon,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@xeris/components';
import { brandApi } from '@xeris/pages/admin/api';

type DeleteBrandDialogProps = {
    isOpen: boolean;
    brand?: {
        id: string;
        name: string;
    };
    handleCloseDialog: () => void;
};

const DeleteBrandDialog = ({
    isOpen,
    brand,
    handleCloseDialog,
}: DeleteBrandDialogProps): ReactElement => {
    const { t } = useTranslation('administration');

    const [deleteBrandMutation, { isLoading }] =
        brandApi.useDeleteBrandMutation();

    const handleDeleteBrand = async (): Promise<void> => {
        if (!brand) {
            return;
        }

        await deleteBrandMutation({ id: brand.id }).unwrap();

        handleCloseDialog();
    };

    const brandName = brand?.name;

    return (
        <Dialog open={isOpen} onClose={handleCloseDialog}>
            <DialogTitle>
                <Typography noWrap variant={'h2'} component={'p'}>
                    {t('brand.deleteBrand.deleteNamedBrand', {
                        brandName,
                    })}
                </Typography>
            </DialogTitle>

            <DialogContent>
                {t('brand.deleteBrand.deletingBrandWillDeleteProducts')}
            </DialogContent>

            <DialogActions>
                <Button variant="textNeutral" onClick={handleCloseDialog}>
                    {t('brand.common.cancel')}
                </Button>
                <LoadingButton
                    variant={'contained'}
                    color={'error'}
                    loading={isLoading}
                    onClick={handleDeleteBrand}
                    startIcon={<DeleteIcon />}
                >
                    {t('brand.common.delete')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteBrandDialog;
